import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xxl-12" }
const _hoisted_3 = { class: "basic-pagination mt-30" }
const _hoisted_4 = { class: "d-flex align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", _hoisted_4, [
          _createElementVNode("li", {
            class: _normalizeClass(["prev", {'d-none': _ctx.currentPage === 1}]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.pageChange(_ctx.currentPage - 1)))
          }, _cache[2] || (_cache[2] = [
            _createElementVNode("div", { class: "link-btn link-prev cursor-pointer" }, [
              _createTextVNode(" Prev "),
              _createElementVNode("i", { class: "arrow_left" }),
              _createElementVNode("i", { class: "arrow_left" })
            ], -1)
          ]), 2),
          _createElementVNode("li", {
            class: _normalizeClass(["pagination-slot", {active: _ctx.slot1(_ctx.currentPage).active}])
          }, [
            _createElementVNode("div", null, [
              _createElementVNode("span", null, _toDisplayString(_ctx.slot1(_ctx.currentPage).value), 1)
            ])
          ], 2),
          _createElementVNode("li", {
            class: _normalizeClass(["pagination-slot", {active: _ctx.slot2(_ctx.currentPage, _ctx.totalPages).active, 'd-none': _ctx.totalPages < 2}])
          }, [
            _createElementVNode("div", null, [
              _createElementVNode("span", null, _toDisplayString(_ctx.slot2(_ctx.currentPage, _ctx.totalPages).value), 1)
            ])
          ], 2),
          _createElementVNode("li", {
            class: _normalizeClass(["pagination-slot", {active: _ctx.slot3(_ctx.currentPage, _ctx.totalPages).active, 'd-none': (_ctx.totalPages < 3 || _ctx.currentPage === _ctx.totalPages)}])
          }, [
            _createElementVNode("div", null, [
              _createElementVNode("span", null, _toDisplayString(_ctx.slot3(_ctx.currentPage, _ctx.totalPages).value), 1)
            ])
          ], 2),
          _createElementVNode("li", {
            class: _normalizeClass(["next cursor-pointer", {'v-none': _ctx.currentPage === _ctx.totalPages}]),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.pageChange(_ctx.currentPage + 1)))
          }, _cache[3] || (_cache[3] = [
            _createElementVNode("div", { class: "link-btn" }, [
              _createTextVNode(" Next "),
              _createElementVNode("i", { class: "arrow_right" }),
              _createElementVNode("i", { class: "arrow_right" })
            ], -1)
          ]), 2)
        ])
      ])
    ])
  ]))
}