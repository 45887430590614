<template>
  <section class="pt-100">
    <div class="container-fluid d-flex">
      <div class="row flex-fill signuparea-row">
        <div class="col-md-7 col-sm-12 linear-bg-black d-flex flex-column justify-content-start align-items-center p-5">
          <h1 class="mt-30 text-white">{{ $t('signUp.boostYour') }}</h1>
          <ul class="list-inline d-flex flex-column align-items-start">
            <li class="list-inline-item mt-60 mb-60">
              <svg xmlns="http://www.w3.org/2000/svg"
                   width="24" height="24" viewBox="0 0 100.000000 100.000000"
                   preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
                   fill="currentColor" stroke="none">
                  <path d="M127 969 c-48 -40 -67 -77 -67 -134 0 -49 10 -77 42 -111 19 -21 19
-21 -4 -36 -13 -8 -31 -17 -40 -21 -10 -3 -18 -16 -18 -27 0 -30 56 -60 114
-60 42 0 47 -2 41 -17 -4 -10 -12 -34 -18 -53 -10 -35 -8 -37 45 -90 62 -63
95 -125 107 -201 6 -35 16 -59 30 -70 13 -11 21 -29 21 -49 0 -23 10 -42 34
-66 30 -30 40 -34 86 -34 46 0 56 4 86 34 24 24 34 43 34 66 0 20 8 38 21 49
14 11 24 35 30 70 12 76 45 138 107 201 53 53 55 55 45 90 -6 19 -14 43 -18
53 -6 15 -1 17 41 17 58 0 114 30 114 59 0 13 -13 26 -35 37 -44 20 -53 34
-31 46 46 26 62 137 27 194 -26 43 -82 84 -114 84 -33 0 -35 -21 -7 -57 11
-14 20 -40 20 -58 0 -79 -98 -94 -213 -32 -45 24 -82 37 -107 37 -25 0 -62
-13 -107 -37 -115 -62 -213 -47 -213 32 0 18 9 44 20 58 48 61 -7 81 -73 26z
m432 -149 c22 -10 55 -39 76 -62 38 -45 135 -228 135 -256 0 -9 -20 -37 -44
-62 -55 -56 -95 -134 -103 -200 -3 -27 -10 -50 -14 -50 -5 0 -9 7 -9 15 0 10
-10 15 -31 15 -30 0 -31 -2 -27 -35 l4 -35 -46 0 -46 0 4 35 c4 33 3 35 -27
35 -21 0 -31 -5 -31 -15 0 -8 -4 -15 -9 -15 -4 0 -11 23 -14 50 -8 66 -48 144
-103 200 -24 25 -44 53 -44 62 0 28 97 211 135 256 40 46 96 81 133 82 12 0
40 -9 61 -20z"/>
                </g>
              </svg>
              {{ $t('signUp.sellMore') }}
            </li>

            <li class="list-inline-item mb-60">
              <!--              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
                              <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"/>
                            </svg>-->
              <svg xmlns="http://www.w3.org/2000/svg"
                   width="24" height="24" viewBox="0 0 100.000000 100.000000"
                   preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
                   fill="currentColor" stroke="none">
                  <path d="M127 969 c-48 -40 -67 -77 -67 -134 0 -49 10 -77 42 -111 19 -21 19
-21 -4 -36 -13 -8 -31 -17 -40 -21 -10 -3 -18 -16 -18 -27 0 -30 56 -60 114
-60 42 0 47 -2 41 -17 -4 -10 -12 -34 -18 -53 -10 -35 -8 -37 45 -90 62 -63
95 -125 107 -201 6 -35 16 -59 30 -70 13 -11 21 -29 21 -49 0 -23 10 -42 34
-66 30 -30 40 -34 86 -34 46 0 56 4 86 34 24 24 34 43 34 66 0 20 8 38 21 49
14 11 24 35 30 70 12 76 45 138 107 201 53 53 55 55 45 90 -6 19 -14 43 -18
53 -6 15 -1 17 41 17 58 0 114 30 114 59 0 13 -13 26 -35 37 -44 20 -53 34
-31 46 46 26 62 137 27 194 -26 43 -82 84 -114 84 -33 0 -35 -21 -7 -57 11
-14 20 -40 20 -58 0 -79 -98 -94 -213 -32 -45 24 -82 37 -107 37 -25 0 -62
-13 -107 -37 -115 -62 -213 -47 -213 32 0 18 9 44 20 58 48 61 -7 81 -73 26z
m432 -149 c22 -10 55 -39 76 -62 38 -45 135 -228 135 -256 0 -9 -20 -37 -44
-62 -55 -56 -95 -134 -103 -200 -3 -27 -10 -50 -14 -50 -5 0 -9 7 -9 15 0 10
-10 15 -31 15 -30 0 -31 -2 -27 -35 l4 -35 -46 0 -46 0 4 35 c4 33 3 35 -27
35 -21 0 -31 -5 -31 -15 0 -8 -4 -15 -9 -15 -4 0 -11 23 -14 50 -8 66 -48 144
-103 200 -24 25 -44 53 -44 62 0 28 97 211 135 256 40 46 96 81 133 82 12 0
40 -9 61 -20z"/>
                </g>
              </svg>
              {{ $t('signUp.registerAndWin') }}
            </li>

            <li class="list-inline-item mb-60">
              <svg xmlns="http://www.w3.org/2000/svg"
                   width="24" height="24" viewBox="0 0 100.000000 100.000000"
                   preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
                   fill="currentColor" stroke="none">
                  <path d="M127 969 c-48 -40 -67 -77 -67 -134 0 -49 10 -77 42 -111 19 -21 19
-21 -4 -36 -13 -8 -31 -17 -40 -21 -10 -3 -18 -16 -18 -27 0 -30 56 -60 114
-60 42 0 47 -2 41 -17 -4 -10 -12 -34 -18 -53 -10 -35 -8 -37 45 -90 62 -63
95 -125 107 -201 6 -35 16 -59 30 -70 13 -11 21 -29 21 -49 0 -23 10 -42 34
-66 30 -30 40 -34 86 -34 46 0 56 4 86 34 24 24 34 43 34 66 0 20 8 38 21 49
14 11 24 35 30 70 12 76 45 138 107 201 53 53 55 55 45 90 -6 19 -14 43 -18
53 -6 15 -1 17 41 17 58 0 114 30 114 59 0 13 -13 26 -35 37 -44 20 -53 34
-31 46 46 26 62 137 27 194 -26 43 -82 84 -114 84 -33 0 -35 -21 -7 -57 11
-14 20 -40 20 -58 0 -79 -98 -94 -213 -32 -45 24 -82 37 -107 37 -25 0 -62
-13 -107 -37 -115 -62 -213 -47 -213 32 0 18 9 44 20 58 48 61 -7 81 -73 26z
m432 -149 c22 -10 55 -39 76 -62 38 -45 135 -228 135 -256 0 -9 -20 -37 -44
-62 -55 -56 -95 -134 -103 -200 -3 -27 -10 -50 -14 -50 -5 0 -9 7 -9 15 0 10
-10 15 -31 15 -30 0 -31 -2 -27 -35 l4 -35 -46 0 -46 0 4 35 c4 33 3 35 -27
35 -21 0 -31 -5 -31 -15 0 -8 -4 -15 -9 -15 -4 0 -11 23 -14 50 -8 66 -48 144
-103 200 -24 25 -44 53 -44 62 0 28 97 211 135 256 40 46 96 81 133 82 12 0
40 -9 61 -20z"/>
                </g>
              </svg>

              {{ $t('signUp.opportunity') }}
            </li>
          </ul>
        </div>
        <div class="col-md-5 col-sm-12 blue-bg-2 d-flex justify-content-center align-items-center p-relative">
          <div class="sign-home__wrapper">
            <div class="sign-home__form">
              <div class="sign-home__input-wrapper">
                <h5>{{ $t('signUp.name') }}</h5>
                <div class="sign-home__input">
                  <input type="text" v-model="fullName" :placeholder="$t('signUp.yourName')" required>
                  <i class="fal fa-user"></i>
                </div>
              </div>
              <div class="sign-home__input-wrapper">
                <h5>{{ $t('signUp.email') }}</h5>
                <div class="sign-home__input">
                  <input type="text" v-model="email" :placeholder="$t('signUp.yourEmail')" required>
                  <i class="fal fa-envelope"></i>
                </div>
              </div>
              <div class="sign-home__input-wrapper">
                <h5>{{ $t('signUp.password') }}</h5>
                <div class="sign-home__input">
                  <input :type="showPassword? 'text': 'password'" v-model="password"
                         :placeholder="$t('signUp.yourPassword')" required>
                  <i class="fal fa-lock"></i>
                  <a @click="togglePasswordVisibility">
                    <span :class="showPassword ? 'fal fa-eye-slash' : 'fal fa-eye'"
                          class="sign-home__input-wrapper-icon-right"></span></a>
                </div>
              </div>

              <div class="sign-home__action d-flex justify-content-between mb-30 mt-3">
                <div class="sign-home__agree d-flex align-items-center">
                  <input class="m-check-input" type="checkbox" id="m-agree" v-model="termsAccepted" require>
                  <label class="m-check-label" for="m-agree">{{ $t('signUp.accept') }}
                    <a
                        href="#">{{ $t('signUp.terms') }} </a>
                  </label>
                </div>
              </div>
              <button class="e-btn w-100"
                      type="button"
                      :disabled="disableButton"
                      @click="register">
                {{ $t('signUp.register') }}
              </button>
              <div class="sign__new text-center mt-20">
                <p>{{ $t('signUp.alreadyRegistered') }}
                  <a href="https://consumer.bullfy.com/">{{ $t('signUp.signIn') }}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div>
    <b-modal id="modal-signup-otp" centered v-model="isModalOTPVisible">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-body">
              <h3>{{ $t('signUp.otpTitle') }}</h3>
              <div class="contact__form">
                <form>
                  <div class="row">
                    <div class="col-xxl-12">
                      <span>&nbsp;{{ $t('signUp.otp') }} <i class="red">*</i></span>
                      <div class="contact__form-input">
                        <input type="text" name="title" v-model="otp">
                      </div>
                    </div>
                    <p>{{ $t('signUp.otpText') }}</p>

                    <div class="col-xxl-12">
                      <div class="contact__btn">
                        <button type="button" @click="confirmOTP" class="e-btn" :disabled="disableConfirmButton">{{ $t('signUp.confirmOTP') }}</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {useToast} from "vue-toastification";
import {AuthUseCase} from "@/core/usecases/auth.usecase";
import {useStore} from "vuex";
import {key} from "@/infrastructure/di/store";

export default defineComponent({
  name: "SignUpArea",
  setup() {
    const store = useStore(key);
    return {
      store
    }
  },
  data() {
    return {
      userId: '',
      email: '',
      password: '',
      fullName: '',
      showPassword: false,
      termsAccepted: false,
      toast: useToast(),
      disableConfirmButton: false,
      disableButton: false,
      isModalOTPVisible: false,
      otp: ''
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword
    },
    goToRegister() {
      this.$router.push('/register');
    },
    async confirmOTP(){
      this.disableConfirmButton = true;
      if(!this.otp || this.otp.length !== 6){
        this.toast.error(this.$t('signUp.otpText'));
        this.disableConfirmButton = false;
        return;
      }
      try {
        const useCase = new AuthUseCase(this.store);
        const res = await useCase.validateOTP(this.userId, this.email, this.otp);
        console.log(res);
        if (res && res.success) {
          this.isModalOTPVisible = false;
          this.toast.success(this.$t('signUp.success'));
          AuthUseCase.saveAuthInfo(res.accessToken, res.refreshToken,this.fullName, this.email);
          await this.$router.push('/courses');
        }else{
          this.toast.error(this.$t('signUp.otpText'));
        }
      } catch (error: any) {
        console.log(error);
        if(error?.data && error?.data?.message === 'USER_ALREADY_EXISTS_ERROR'){
          this.toast.error(this.$t('signUp.mailAlreadyRegistered'));
        }else {
          this.toast.error(this.$t('signUp.error'));
        }
      }
      this.disableConfirmButton = false;
    },
    async register() {
      console.log('register');
      this.disableButton = true;
      if (!this.fullName) {
        this.toast.warning(this.$t('signUp.notValidName'));
      } else if (!this.validateEmail(this.email)) {
        this.toast.warning(this.$t('signUp.notValidEmail'));
      } else if (!this.password) {
        this.toast.warning(this.$t('signUp.noPassword'));
      }else if (!this.termsAccepted) {
        this.toast.warning(this.$t('signUp.acceptTerms'));
      } else {
        const useCase = new AuthUseCase(this.store);
        try {
          const res = await useCase.register(this.email, this.password, this.fullName);
          if (res && res.userId) {
            console.log(res);
            this.userId = res.userId;
            this.isModalOTPVisible = true;
          }else{
            this.toast.error(this.$t('signUp.error'));
          }
        } catch (error: any) {
          console.log(error);
          if(error?.data && error?.data?.message === 'USER_ALREADY_EXISTS_ERROR'){
            this.toast.error(this.$t('signUp.mailAlreadyRegistered'));
          }else {
            this.toast.error(this.$t('signUp.error'));
          }
        }
      }
      this.disableButton = false;
    },
    validateEmail(email: string) {
      const regex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
      return regex.test(email);
    }
  }
});
</script>

<style scoped>

.modal-content{
  max-width: 800px!important;
  width: 600px;
}
.modal-dialog{
  width: 300px;
}
.modal-body{
  padding: 1rem 0.3rem !important;
}

.list-inline-item {
  font-size: 18px;
}

.signuparea-row {
  min-height: 40rem;
}

.e-btn:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
  opacity: 0.6;
}

@media screen and (max-width: 768px) {
  .container-left, .container-right {
    max-width: 90vw;
    margin: auto;
    text-align: center;
  }
}

@media screen and (max-width: 450px) {
  .signuparea-row {
    min-height: 12rem;
  }
}
</style>