import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "course__area pt-120 pb-120" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "header__btn ml-20 d-none d-sm-block mb-4" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-xxl-12" }
const _hoisted_6 = { class: "course__tab-conent" }
const _hoisted_7 = {
  class: "tab-content",
  id: "courseTabContent"
}
const _hoisted_8 = {
  class: "tab-pane fade",
  id: "grid",
  role: "tabpanel",
  "aria-labelledby": "grid-tab"
}
const _hoisted_9 = { class: "row" }
const _hoisted_10 = {
  class: "tab-pane fade show active",
  id: "list",
  role: "tabpanel",
  "aria-labelledby": "list-tab"
}
const _hoisted_11 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_GridTabItems = _resolveComponent("GridTabItems")!
  const _component_ListTabItems = _resolveComponent("ListTabItems")!
  const _component_Pagination = _resolveComponent("Pagination")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: "/new-course",
          class: "e-btn"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Crear un curso")
          ])),
          _: 1
        })
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "course__tab-inner grey-bg-2 mb-50" }, [
        _createElementVNode("div", { class: "row align-items-center" }, [
          _createElementVNode("div", { class: "col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6" }, [
            _createElementVNode("div", { class: "course__tab-wrapper d-flex align-items-center" }, [
              _createElementVNode("div", { class: "course__tab-btn" }, [
                _createElementVNode("ul", {
                  class: "nav nav-tabs",
                  id: "courseTab",
                  role: "tablist"
                }, [
                  _createElementVNode("li", {
                    class: "nav-item",
                    role: "presentation"
                  }, [
                    _createElementVNode("button", {
                      class: "nav-link",
                      id: "grid-tab",
                      "data-bs-toggle": "tab",
                      "data-bs-target": "#grid",
                      type: "button",
                      role: "tab",
                      "aria-controls": "grid",
                      "aria-selected": "true"
                    }, [
                      _createElementVNode("svg", {
                        class: "grid",
                        viewBox: "0 0 24 24"
                      }, [
                        _createElementVNode("rect", {
                          x: "3",
                          y: "3",
                          class: "st0",
                          width: "7",
                          height: "7"
                        }),
                        _createElementVNode("rect", {
                          x: "14",
                          y: "3",
                          class: "st0",
                          width: "7",
                          height: "7"
                        }),
                        _createElementVNode("rect", {
                          x: "14",
                          y: "14",
                          class: "st0",
                          width: "7",
                          height: "7"
                        }),
                        _createElementVNode("rect", {
                          x: "3",
                          y: "14",
                          class: "st0",
                          width: "7",
                          height: "7"
                        })
                      ])
                    ])
                  ]),
                  _createElementVNode("li", {
                    class: "nav-item",
                    role: "presentation"
                  }, [
                    _createElementVNode("button", {
                      class: "nav-link list active",
                      id: "list-tab",
                      "data-bs-toggle": "tab",
                      "data-bs-target": "#list",
                      type: "button",
                      role: "tab",
                      "aria-controls": "list",
                      "aria-selected": "false"
                    }, [
                      _createElementVNode("svg", {
                        class: "list",
                        viewBox: "0 0 512 512"
                      }, [
                        _createElementVNode("g", { id: "Layer_2_1_" }, [
                          _createElementVNode("path", {
                            class: "st0",
                            d: "M448,69H192c-17.7,0-32,13.9-32,31s14.3,31,32,31h256c17.7,0,32-13.9,32-31S465.7,69,448,69z"
                          }),
                          _createElementVNode("circle", {
                            class: "st0",
                            cx: "64",
                            cy: "100",
                            r: "31"
                          }),
                          _createElementVNode("path", {
                            class: "st0",
                            d: "M448,225H192c-17.7,0-32,13.9-32,31s14.3,31,32,31h256c17.7,0,32-13.9,32-31S465.7,225,448,225z"
                          }),
                          _createElementVNode("circle", {
                            class: "st0",
                            cx: "64",
                            cy: "256",
                            r: "31"
                          }),
                          _createElementVNode("path", {
                            class: "st0",
                            d: "M448,381H192c-17.7,0-32,13.9-32,31s14.3,31,32,31h256c17.7,0,32-13.9,32-31S465.7,381,448,381z"
                          }),
                          _createElementVNode("circle", {
                            class: "st0",
                            cx: "64",
                            cy: "412",
                            r: "31"
                          })
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", { class: "course__view" }, [
                _createElementVNode("h4", null, "Showing 1 - 9 of 84")
              ])
            ])
          ]),
          _createElementVNode("div", { class: "col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6" }, [
            _createElementVNode("div", { class: "course__sort d-flex justify-content-sm-end" }, [
              _createElementVNode("div", { class: "course__sort-inner" }, [
                _createElementVNode("select", null, [
                  _createElementVNode("option", null, "Default"),
                  _createElementVNode("option", null, "Option 1"),
                  _createElementVNode("option", null, "Option 2"),
                  _createElementVNode("option", null, "Option 3"),
                  _createElementVNode("option", null, "Option 4"),
                  _createElementVNode("option", null, "Option 5"),
                  _createElementVNode("option", null, "Option 6")
                ])
              ])
            ])
          ])
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_GridTabItems, { courses: _ctx.courses }, null, 8, ["courses"])
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_ListTabItems, { courses: _ctx.courses }, null, 8, ["courses"])
                ])
              ])
            ])
          ])
        ])
      ]),
      _createVNode(_component_Pagination, {
        "current-page": _ctx.courses.metadata.page,
        "total-pages": _ctx.courses.metadata.totalPages,
        "onUpdate:page": _cache[0] || (_cache[0] = page => _ctx.getCourses(page, _ctx.$i18n.locale))
      }, null, 8, ["current-page", "total-pages"])
    ])
  ]))
}