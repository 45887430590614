<script>
export default {
  name: 'ModuleFive',
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<template>
  <section>
    <div class="container-fluid container-module black-bg">
      <div class="row">
        <div class="text-center col-sm-12">
          <h2 class="section__title white-color">
            <span class="yellow-bg"> {{ $t('home.lessStress.title.frag1') }}
              <img src="@/assets/img/shape/yellow-bg.png" alt="">
            </span>
            {{ $t('home.lessStress.title.frag2') }}
          </h2>
        </div>

        <div class="text-center white-color">
          <span>{{ $t('home.lessStress.body') }}</span>
        </div>


        <div class="row row-module">
          <div class="col-xxl-7 col-xl-7 col-lg-6 col-sm-12">
            <div class="contact__info-inner white-bg">
              <ul>
                <li class="p-relative">
                  <div class="contact__info-item d-flex align-items-start mb-35">
                    <div class="contact__info-icon mr-15">
                      <h1>01.</h1>
                    </div>
                    <div class="contact__info-text">
                      <h3>{{ $t('home.lessStress.step1.title') }}</h3>
                      <p>{{ $t('home.lessStress.step1.body') }}</p>
                    </div>
                  </div>
                </li>
                <li class="p-relative">
                  <div class="contact__info-item  d-flex align-items-start mb-35">
                    <div class="contact__info-icon mr-15">
                      <h1>02.</h1>
                      <!--                      <svg class="map" viewBox="0 0 24 24">
                                              <path class="st0" d="M21,10c0,7-9,13-9,13s-9-6-9-13c0-5,4-9,9-9S21,5,21,10z"/>
                                              <circle class="st0" cx="12" cy="10" r="3"/>
                                            </svg>-->
                    </div>
                    <div class="contact__info-text">
                      <h3>{{ $t('home.lessStress.step2.title') }}</h3>
                      <p>{{ $t('home.lessStress.step2.body') }}</p>
                    </div>
                  </div>
                </li>
                <li class="p-relative">
                  <div class="contact__info-item d-flex align-items-start mb-35">
                    <div class="contact__info-icon mr-15">
                      <h1>03.</h1>
                      <!--                      <svg class="map" viewBox="0 0 24 24">
                                              <path class="st0" d="M21,10c0,7-9,13-9,13s-9-6-9-13c0-5,4-9,9-9S21,5,21,10z"/>
                                              <circle class="st0" cx="12" cy="10" r="3"/>
                                            </svg>-->
                    </div>
                    <div class="contact__info-text">
                      <h3>{{ $t('home.lessStress.step3.title') }}</h3>
                      <p>{{ $t('home.lessStress.step3.body') }}</p>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="contact__social">
                <router-link to="#" @click.prevent="scrollToTop" class="e-btn bg-439AD9 d-flex justify-content-center">
                  {{ $t('actions.startNow') }}
                </router-link>

              </div>
            </div>
          </div>
          <div class="col-xxl-5 col-xl-5 col-lg-4">
            <img src="@/assets/img/hero/hero-1.jpg" alt="" class="w-100">
          </div>

        </div>
      </div>
    </div>

  </section>
</template>

<style>
.white-color {
  color: #FFFFFF !important;
}

.contact__info-item:before {
  border-left: 2px dashed #a1a2ab;
  height: 3.3em;
  content: "";
  left: 1.5em;
  position: absolute;
  top: 3em;
}

.container-module {
  padding: 3.9rem;
  margin-top: 2.7rem;
}

.row-module {
  margin-top: 3rem;
  height: 100% !important;
}

.contact__info-inner {
  height: 100%;
}

@media screen and (max-width: 450px) {
  .row-module {
    margin-top: 0rem;
    padding: 0;
    width: 100%;
  }

  .contact__info-inner {
    height: 100%;
  }
}
</style>
