<style>
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

.order-total {
  text-align: end;
}

.sign-home__input-wrapper {
  margin-top: 2rem;
}
</style>
<template>
  <section class="checkout-area mt-15">
      <div class="container">
            <div class="row">
                  <div class="col-lg-6">
                     <div class="checkbox-form">
                       <h3>{{ $t('billing.billingDetails') }}</h3>
                        <div class="row">
<!--
                              <div class="col-md-12">
                                 <div class="country-select">
                                    <label>Country <span class="required">*</span></label>
                                   <select v-model="selectedCountry">
                                     <option v-for="country in countries" :key="country.code" :value="country.name">
                                       {{ country.name }}
                                     </option>
                                   </select>
                                 </div>
                              </div>
-->

                          <div class="col-md-6">
                            <div class="checkout-form-list">
                              <label>{{ $t('signUp.firstName') }} <span class="required">*</span></label>
                              <input type="text" v-model="formData.firstName" placeholder="First Name" class="text-capitalize"/>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="checkout-form-list">
                              <label>{{ $t('signUp.lastName') }} <span class="required">*</span></label>
                              <input type="text" v-model="formData.lastName" placeholder="Last Name" class="text-capitalize"/>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="checkout-form-list">
                              <label>{{$t('billing.email')}} <span class="required">*</span></label>
                              <input type="email" v-model="formData.email" placeholder="Email"/>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="checkout-form-list">
                              <label>{{ $t('billing.phone') }} </label>
                              <input type="text" v-model="formData.phone" placeholder="+1 903 343443"/>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div :class="['checkout-form-list create-acc', { 'highlight':  showHighlight }]">
                              <input type="checkbox" v-model="formData.createAccount" id="cbox" @change="handleCheckboxChange"/>
                              <label for="cbox">{{ $t('billing.createAccountTitle') }}</label>
                            </div>
                            <div id="cbox_info" :class="`checkout-form-list ${showAcc ? 'show-create-acc' : 'create-account'}`">
                              <p>{{$t('billing.createAccountDetail')}}</p>
                              <label>Account password <span class="required">*</span></label>
                              <input type="password" v-model="formData.password" placeholder="password"/>
                            </div>
                          </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="your-order mb-30 ">
                       <h3>{{ $t('billing.yourOrder') }}</h3>
                        <div class="your-order-table table-responsive">
                              <table>
                                 <thead>
                                    <tr>
                                      <th class="product-name">{{ $t('billing.products') }}</th>
                                       <th class="product-total">Total</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                 <tr class="cart_item" v-for="item in items" :key="item.courseId">
                                       <td class="product-name">
                                         {{ item?.courseName }}<strong class="product-quantity"> × {{ item.quantity }}</strong>
                                       </td>
                                       <td class="product-total">
                                         <span class="amount">${{ item?.total?.toFixed(2) }}</span>
                                       </td>
                                 </tr>
                                 </tbody>
                                 <tfoot>

                                    <tr class="order-total">
                                      <th class="text-center">{{ $t('billing.totalOrder') }}</th>
                                       <td><strong><span class="amount">${{ formattedCartTotal }}</span></strong>
                                       </td>
                                    </tr>
                                 </tfoot>
                              </table>
                        </div>

                        <div class="payment-method">
                           <div class="accordion" id="checkoutAccordion">
<!--                              <div class="accordion-item">
                                 <h2 class="accordion-header" id="checkoutOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#bankOne" aria-expanded="true" aria-controls="bankOne">
                                    Direct Bank Transfer
                                    </button>
                                 </h2>
                                 <div id="bankOne" class="accordion-collapse collapse show" aria-labelledby="checkoutOne" data-bs-parent="#checkoutAccordion">
                                    <div class="accordion-body">
                                    Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order won’t be shipped until the funds have cleared in our account.
                                    </div>
                                 </div>
                              </div>
                              <div class="accordion-item">
                                 <h2 class="accordion-header" id="paymentTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#payment" aria-expanded="false" aria-controls="payment">
                                    Cheque Payment
                                    </button>
                                 </h2>
                                 <div id="payment" class="accordion-collapse collapse" aria-labelledby="paymentTwo" data-bs-parent="#checkoutAccordion">
                                    <div class="accordion-body">
                                    Please send your cheque to Store Name, Store Street, Store Town, Store
                                    State / County, Store
                                    Postcode.
                                    </div>
                                 </div>
                              </div>-->
                              <div class="accordion-item">
                                 <h2 class="accordion-header" id="paypalThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#paypal" aria-expanded="false" aria-controls="paypal">
                                      Stripe <i class="fa fa-stripe"></i>
                                    </button>
                                 </h2>
                                 <div id="paypal" class="accordion-collapse collapse" aria-labelledby="paypalThree" data-bs-parent="#checkoutAccordion">
                                    <div class="accordion-body">
                                      <div class="stripe-form">
                                        <form id="payment-form" @submit.prevent="executePayment">
                                          <label for="card-element">
                                           {{$t('billing.creditOrDebit')}}
                                          </label>
                                          <div id="card-element"><!-- Aquí se renderizará la tarjeta --></div>
                                          <button id="submit" v-if="showPayButton" class="e-btn e-btn-border mt-3 w-100">{{$t('billing.pay')}}
                                          </button>
                                          <div id="error-message" class="error-message"><!-- Mensajes de error --></div>
                                        </form>
                                      </div>
                                    </div>
                                 </div>
                              </div>
                              </div>
                              <div class="order-button-payment mt-20">
                                <button type="button" @click="submitOrder" class="e-btn e-btn-border" v-if="!showPayButton">
                                  {{ $t('billing.placeOrder') }}
                                </button>
                              </div>
                        </div>
                     </div>
                  </div>
            </div>
      </div>

    <div>
      <b-modal id="modal-signup-otp" centered v-model="showModalCreateAccount">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <div class="modal-body">
                <h3>{{ $t('signUp.register') }}</h3>
                <h6>{{ $t('signUp.sellMore') }}</h6>
                <div class="contact__form">
                  <form>
                    <div class="row">
                      <div class="sign-home__input-wrapper">
                        <h5>{{ $t('signUp.name') }}</h5>
                        <div class="sign-home__input">
                          <input type="text" class="text-capitalize" v-model="formData.fullName" :placeholder="$t('signUp.yourName')" required>
                          <i class="fal fa-user"></i>
                        </div>
                      </div>
                      <div class="sign-home__input-wrapper">
                        <h5>{{ $t('signUp.email') }}</h5>
                        <div class="sign-home__input">
                          <input type="text" v-model="formData.email" :placeholder="$t('signUp.yourEmail')" required>
                          <i class="fal fa-envelope"></i>
                        </div>
                      </div>
                      <div class="sign-home__input-wrapper">
                        <h5>{{ $t('signUp.password') }}</h5>
                        <div class="sign-home__input">
                          <input :type="showPassword? 'text': 'password'" v-model="formData.password"
                                 :placeholder="$t('signUp.yourPassword')" required>
                          <i class="fal fa-lock"></i>
                          <a @click="togglePasswordVisibility">
                            <span :class="showPassword ? 'fal fa-eye-slash' : 'fal fa-eye'"
                                  class="sign-home__input-wrapper-icon-right"></span></a>
                        </div>
                      </div>
                      <div class="sign-home__action d-flex justify-content-between mb-30 mt-3">
                        <div class="sign-home__agree d-flex align-items-center">
                          <input class="m-check-input" type="checkbox" id="m-agree" v-model="termsAccepted" require>
                          <label class="m-check-label" for="m-agree">{{ $t('signUp.accept') }}
                            <a
                              href="#">{{ $t('signUp.terms') }} </a>
                          </label>
                        </div>
                      </div>


                      <div class="col-xxl-12">
                        <div class="contact__btn">
                          <button type="button" @click="createAccount" class="e-btn" :disabled="disableCreateButton">
                            {{ $t('signUp.createAccount') }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>

    <div>
      <b-modal id="modal-signup-otp" centered v-model="showModalConfirmOTP">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <div class="modal-body">
                <h3>{{ $t('signUp.otpTitle') }}</h3>
                <div class="contact__form">
                  <form>
                    <div class="row">
                      <div class="col-xxl-12">
                        <span>&nbsp;{{ $t('signUp.otp') }} <i class="red">*</i></span>
                        <div class="contact__form-input">
                          <input type="text" name="title" v-model="otp">
                        </div>
                      </div>
                      <p>{{ $t('signUp.otpText') }}</p>

                      <div class="col-12">
                        <div class="contact__btn">
                          <button type="button" @click="confirmOTP" class="e-btn" :disabled="disableConfirmButton">
                            {{ $t('signUp.confirmOTP') }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
   </section>
</template>

<script>
import { mapGetters, useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import { PaymentsUseCase } from '@/core/usecases/payments.usecase';
import { key } from '@/infrastructure/di/store';
import { AuthUseCase } from '@/core/usecases/auth.usecase';
import { loadStripe } from '@stripe/stripe-js';
import { StorageConstants } from '@/core/domain/constants/storage.constants';

export default {
   name:'CheckoutArea',
  components: {},
  setup() {
    const store = useStore(key);
    return {
      store
    }
  },
   data () {
      return {
        toast: useToast(),
        useCase: new PaymentsUseCase(this.store),
        userUseCase: new AuthUseCase(this.store),
         showAcc:false,
         shipBox:false,
        countries: [],
        selectedCountry: '',
        showHighlight: false,
        processing: false,
        elements: null,
        stripe: null,
        card: null,
        showPayButton: false,
        showModalConfirmOTP: false,
        showModalCreateAccount: false,
        disableCreateButton: false,
        disableConfirmButton: false,
        termsAccepted: false,
        showPassword: false,
        clientSecret: null,
        otp: null,
        userId: null,
        formData: {
          firstName: '',
          fullName: '',
          lastName: '',
          email: '',
          phone: '',
          password: '',
          createAccount: false,
          items: [],
        },
      }
   },
  methods: {
    async fetchCountries() {
      const response = await fetch('https://restcountries.com/v3.1/all?fields=cca2,name,flags');
      const data = await response.json();
      this.countries = data.map((country) => ({
        name: country.name.common,
        code: country.cca2,
        flag: country.flags.svg
      }))
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    showCreateAcc() {
      return this.showAcc = !this.showAcc
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword
    },
    async validateUser() {
      try {
        console.log('valid')
        const response = await this.userUseCase.emailExists(this.formData.email);
        if (response.exists) {
          this.userId = response.userId;
        } else {
          this.showHighlight = true;
          this.toast.info(this.$t('signUp.userNotFound'));
        }
        return response.exists;
      } catch (error) {
        if (error?.status === 400) {
          switch (error?.data?.message) {
            case 'EMAIL_NOT_VALID_ERROR':
              this.toast.error(this.$t('signUp.invalidEmail'));
              break;
            case 'USER_IS_NOT_ACTIVE_ERROR':
              this.showHighlight = true;
              this.toast.info(this.$t('signUp.userNotFound'));
              break;
            default:
              this.toast.error('Error validating user:', error);
          }
        }else{
          this.toast.error(this.$t('signUp.userNotFound'));
        }
      }
      return false;
    },
    async submitOrder() {
      // Valida los campos requeridos
      if (this.totalItems <= 0) {
        this.toast.info(this.$t('cart.addItems'));
        return;
      }

      this.showHighlight = false;
      if (
        !this.formData.firstName ||
        !this.formData.lastName ||
        !this.formData.email
      ) {
        this.toast.error(this.$t('signUp.fillAllFields'));
        return;
      }

      let creatorId = '';
      let creatorName = '';
      if(this.items.length > 0){
        creatorId = this.items[0].creatorId;
        creatorName = this.items[0].creatorName;
      }
      const details = this.items.map((item) => ({
        courseId: item.courseId,
        courseName: item.courseName,
        quantity: item.quantity,
        price: item.price,
        total: item.total,
      }));

      const payment = {
        details,
        firstName: this.formData.firstName,
        lastName: this.formData.lastName,
        email: this.formData.email,
        phone: this.formData.phone,
        country: this.selectedCountry,
        createAccount: this.formData.createAccount,
        totalToPay: this.cartTotal,
        creatorId: creatorId,
        creatorName: creatorName,
      };

      try {
        if (!await this.validateUser()) {
          return;
        }

        if (this.cartTotal === 0) {
          for (const courseInfo of this.items) {
            const assignedResponse = await this.useCase.assignCourse(this.userId, courseInfo.courseId, 0, null);
            if (!assignedResponse) {
              throw new Error('Failed to assign course.');
            }
          }
          this.toast.success(this.$t('signUp.paymentConfirmed'));
          this.resetForm();
          await this.store.dispatch('shoppingCart/clearCart');
          window.location.href = 'https://consumer.bullfy.com';
        } else {
          const paymentResponse = await this.useCase.createPayment(payment);
          console.log(paymentResponse);
          if (!paymentResponse.paymentId) {
            throw new Error('Failed to place order.');
          }
          this.openAccordion('paypal');
          await this.loadStripeView(paymentResponse.publicKey, paymentResponse.externalClientSecret);
          this.toast.success(this.$t('signUp.orderPlaced'));
        }
        this.resetForm();
      } catch (error) {

        switch (error?.status) {
          case 400:
            this.toast.error('User not Found, Please click on Create Account CheckBox Button');
            break;
          case 401:
            this.toast.error('Unauthorized');
            break;
          case 403:
            this.toast.error('Forbidden');
            break;
          case 404:
            this.toast.error('Not Found');
            break;
          case 500:
            this.toast.error('Internal Server Error');
            break;
          default:
            this.toast.error('Error placing order:', error);
        }
      }
    },
    resetForm() {
      this.formData = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        createAccount: false,
        items: [],
      };
      this.selectedCountry = '';
    },
    async confirmOTP() {
      this.disableConfirmButton = true;
      if (!this.otp || this.otp.length !== 6) {
        this.toast.error(this.$t('signUp.otpText'));
        this.disableConfirmButton = false;
        return;
      }
      try {
        const useCase = new AuthUseCase(this.store);
        const res = await useCase.validateOTP(this.userId, this.formData.email, this.otp);

        if (res && res.success) {
          this.toast.success(this.$t('signUp.success'));
          AuthUseCase.saveAuthInfo(res.accessToken, res.refreshToken, this.fullName, this.email);
          this.showModalConfirmOTP = false;
        } else {
          this.toast.error(this.$t('signUp.otpText'));
        }
      } catch (error) {
        console.log(error);
        if (error?.data && error?.data?.message === 'USER_ALREADY_EXISTS_ERROR') {
          this.toast.error(this.$t('signUp.mailAlreadyRegistered'));
        } else {
          this.toast.error(this.$t('signUp.error'));
        }
      }
      this.disableConfirmButton = false;
    },
    async executePayment() {
      this.processing = true;
      try {
        const { paymentIntent, error } = await this.stripe.confirmCardPayment(this.clientSecret, {
          payment_method: {
            card: this.card,
            billing_details: {
              name: `${this.formData.firstName} ${this.formData.lastName}`,
              email: this.formData.email,
              address: {
                postal_code: '00000',
              },
            },
          },
        });
        console.log(paymentIntent)
        if (error) {
          document.getElementById('error-message').textContent = error.message;
          return;
        } else {
          if (paymentIntent.status === 'succeeded') {
            paymentIntent.confirmation_method;
            paymentIntent.amount;

            const serverPaymentId = await this.useCase.confirmPayment(
              paymentIntent.status, paymentIntent.id, paymentIntent.client_secret,
              paymentIntent.confirmation_method,
              paymentIntent.amount);
            if (serverPaymentId) {
              for (const courseInfo of this.items) {
                const assignedResponse = await this.useCase.assignCourse(this.userId, courseInfo.courseId,
                  paymentIntent.amount, serverPaymentId);
                if (!assignedResponse) {
                  throw new Error('Failed to assign course.');
                }
              }

              this.toast.success(this.$t('signUp.paymentConfirmed'));
              this.resetForm();
              await this.store.dispatch('shoppingCart/clearCart');
              window.location.href = 'https://consumer.bullfy.com';
            }
          }
        }
        this.processing = false;
        this.showPayButton = false;
      } catch (e) {
        this.toast.error('Error confirming payment:', e.message);
      }
    },
    async loadStripeView(publicKey, clientSecret) {
      try {
        this.showPayButton = true
        // Carga la clave pública de Stripe
        this.stripe = await loadStripe(publicKey);
        this.clientSecret = clientSecret;
        this.elements = this.stripe.elements();
        this.card = this.elements.create('card', {
          hidePostalCode: true
        });
        this.card.mount('#card-element');
      } catch (error) {
        console.error('Payment error:', error.message);
        this.toast.error('Payment error:', error.message);
      }
    },
    openAccordion(accordionId) {
      const accordion = document.getElementById(accordionId);
      if (accordion) {
        accordion.classList.add('show');
      }
    },
    handleCheckboxChange(event) {
      console.log('Checkbox changed:', event.target.checked);
      this.showModalCreateAccount = event.target.checked;
    },
    async createAccount() {
      this.disableCreateButton = true;
      if (!this.formData.fullName) {
        this.toast.warning(this.$t('signUp.notValidName'));
      } else if (!this.formData.email) {
        this.toast.warning(this.$t('signUp.notValidEmail'));
      } else if (!this.formData.password) {
        this.toast.warning(this.$t('signUp.noPassword'));
      } else if (!this.termsAccepted) {
        this.toast.warning(this.$t('signUp.acceptTerms'));
      } else {
        const useCase = new AuthUseCase(this.store);
        try {
          const res = await useCase.register(this.formData.email, this.formData.password, this.formData.fullName);
          if (res && res.userId) {
            console.log(res);
            this.userId = res.userId;
            this.showModalCreateAccount = false;
            this.showModalConfirmOTP = true;
          } else {
            this.toast.error(this.$t('signUp.error'));
          }
        } catch (error) {
          console.log(error);
          this.showModalCreateAccount = false;
          this.formData.createAccount = false;
          if (error?.data && error?.data?.message === 'USER_ALREADY_EXISTS_ERROR') {
            this.toast.info(this.$t('signUp.mailAlreadyRegistered'));
          } else {
            this.toast.error(this.$t('signUp.error'));
          }
        }
      }
      this.disableCreateButton = false;
    }
  },
  computed: {
    ...mapGetters('shoppingCart', ['items', 'showMiniCart', 'cartTotal', 'totalItems']), // Obtener el estado del carrito mini
    formattedCartTotal() {
      return this.cartTotal ? this.cartTotal.toFixed(2) : '0.00';
    },
    formattedTotalItems() {
      return this.totalItems || 0;
    },
    fullName() {
      return `${this.formData.firstName} ${this.formData.lastName}`.trim();
    }
  },
  watch: {
    fullName(newFullName) {
      this.formData.fullName = newFullName;
    }
  },
  mounted() {
    this.formData.fullName = localStorage.getItem(StorageConstants.USER_NAME);
    if (this.formData.fullName) {
      const [firstName, lastName] = this.formData.fullName.split(' ');
      this.formData.firstName = firstName;
      this.formData.lastName = lastName;
    }
    const email = localStorage.getItem(StorageConstants.USER_EMAIL);
    if(email){
      this.formData.email = email;
    }
    this.fetchCountries();
    this.formData.items = this.items;
  }
};
</script>