import {IPaymentsRepository} from "@/core/domain/repositories/payments.repository";
import {IPaymentsDataSource} from "@/core/domain/datasources/payments.datasource";
import {PaymentsDatasource} from "@/infrastructure/data/datasources/payments.datasource.impl";
import {Payment} from "@/core/domain/entities/payment.entity";


class PaymentsRepository implements IPaymentsRepository {
  private _datasSource: IPaymentsDataSource;

  constructor() {
    this._datasSource = new PaymentsDatasource()
  }

  async createPayment(payment: Payment): Promise<{
    paymentId: string;
    publicKey: string;
    externalClientSecret: string;
  }> {
    return await this._datasSource.createPayment(payment)
  }
 async  confirmPayment(status: string,
                       externalPaymentId: string,
                       externalClientSecret: string,
                       paymentMethod: string,
                       amountPaid: number): Promise<string>{
    return await this._datasSource.confirmPayment(status, externalPaymentId, externalClientSecret, paymentMethod, amountPaid);
 }

 async assignCourse(userId: string, courseId: string, totalPaid: number, paymentId: string): Promise<boolean>
  {
      return await this._datasSource.assignCourse(userId, courseId, totalPaid, paymentId);
  }

}

export {
  PaymentsRepository
}