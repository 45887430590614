import {HttpBase} from '../../di/httpBase';
import {IAuthDataSource} from '@/core/domain/datasources/auth.datasource';
import {User} from '@/core/domain/entities/user.entity';
import {InstructorInputModel} from '@/infrastructure/data/models/instructorInput.model';
import {RoleConstants} from '@/core/domain/constants/role.constants';

export class AuthDataSource implements IAuthDataSource {
    private _httpBase: HttpBase;

    constructor() {
        this._httpBase = new HttpBase();
    }

    async register(email: string, password: string, fullName: string, role: string): Promise<any> {
        return (await this._httpBase.post('/user', {email, password, fullName, role})).data;
    }

    async validateOTP(userId: string, email: string, otp: string): Promise<AuthToken>{
        return (await this._httpBase.post('/user/validate-otp', {userId, email, otp})).data;
    }

    async registerInstructor(body: InstructorInputModel): Promise<boolean> {
        body.role =  RoleConstants.INSTRUCTOR;
        return (await this._httpBase.post('/user', {...body})).data.success;
    }

    async getUserData(): Promise<User> {
        return (await this._httpBase.get('user/me')).data.data;
    }

    async login(email: string, password: string): Promise<AuthToken | null> {
        try {
            const response = await this._httpBase.post('/auth/login', {email, password});
            if (response)
                return response.data.data;
        } catch (error) {
            console.error(error)
            throw error;
        }
        return null;
    }

    async refreshToken(refreshToken: string): Promise<AuthToken> {
        return (await this._httpBase.post('/auth/refresh-access-token', null, {'x-refresh-token-x': refreshToken})).data.data;
    }

    async validateUser(email: string): Promise<{exists: boolean, userId: string}> {
        return (await this._httpBase.get('/user/validate', {email},null)).data;
    }

}