<template>
  <Header header__white="header__white" />
  <Breadcrumb title="About" subtitle="About" />
  <AboutArea/>
  <img src="@/assets/img/img2.png" alt="" class="decorative-image" style="position: absolute; top: 50%; left: 10%;">
  <img src="@/assets/img/img2.png" alt="" class="decorative-image" style="position: absolute; top: 100%; right: 10%;">
  <BrandArea/>
  <Testimonial/>
  <WhyArea/>
  <CounterArea/>
  <BannerArea/>
  <Footer footerPadding="true" />
</template>

<script>
import Header from '../components/Home/Header.vue';
import Breadcrumb from '../components/common/Breadcrumb.vue';
import AboutArea from '../components/common/AboutArea.vue';
import Testimonial from '../components/HomeThree/Testimonial.vue';

import Footer from '../components/Home/Footer.vue';

export default {
    name:'AboutPage',
    components:{
        Header,
        Breadcrumb,
        AboutArea,
        Testimonial,
        Footer,
    }
}
</script>

<style>

@media screen and (max-width: 768px) {
  .decorative-image {
    display: none;
  }
}
</style>
