import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/img/shape/yellow-bg.png'


const _hoisted_1 = { class: "course__area pt-115 pb-120 grey-bg" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row align-items-end" }
const _hoisted_4 = { class: "col-xxl-7 col-xl-6 col-lg-6" }
const _hoisted_5 = { class: "course__menu d-flex justify-content-lg-end mb-60" }
const _hoisted_6 = { class: "masonary-menu filter-button-group" }
const _hoisted_7 = {
  className: "nav nav-tabs justify-content-center",
  id: "portfolio-tab",
  role: "tablist"
}
const _hoisted_8 = {
  className: "nav-link active",
  id: "nav-all-tab",
  "data-bs-toggle": "tab",
  "data-bs-target": "#nav-all",
  type: "button",
  role: "tab",
  "aria-controls": "nav-all",
  "aria-selected": "true"
}
const _hoisted_9 = { class: "tag" }
const _hoisted_10 = { class: "row grid tab-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GridTabItems = _resolveComponent("GridTabItems")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"col-xxl-5 col-xl-6 col-lg-6\"><div class=\"section__title-wrapper mb-60\"><h2 class=\"section__title\">Los mejores<span class=\"yellow-bg yellow-bg-big\"> Cursos<img src=\"" + _imports_0 + "\" alt=\"\"></span> para ti</h2><p>Aquí encontraras los mejores cursos disponibles para tí.</p></div></div>", 1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("nav", null, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("button", _hoisted_8, [
                    _createTextVNode(_toDisplayString(_ctx.$t('actions.seeAll')) + " ", 1),
                    _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('tooltip.new', 2)), 1)
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((new Array(4)), (_, index) => {
                    return (_openBlock(), _createElementBlock("button", {
                      key: index,
                      className: "nav-link",
                      id: "nav-trending-tab",
                      "data-bs-toggle": "tab",
                      "data-bs-target": "#nav-trending",
                      type: "button",
                      role: "tab",
                      "aria-controls": "nav-trending",
                      "aria-selected": "false"
                    }, _toDisplayString(_ctx.categories[index]?.name), 1))
                  }), 128))
                ])
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_GridTabItems, {
          courses: _ctx.courses.data
        }, null, 8, ["courses"])
      ])
    ])
  ]))
}