<template>
  <div class="tab-pane fade" id="curriculum" role="tabpanel" aria-labelledby="curriculum-tab">
    <div class="course__curriculum">
      <div v-for="(module, index) in course.modules" class="accordion" :key="module._id+index"
           :id="index > 0 ? 'course__accordion' : `course__accordion-${index+1}`">
        <div class="accordion-item mb-50">
          <h2 class="accordion-header" :id="module._id">
            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                    :data-bs-target="`#${module._id}-content`"
                    :aria-expanded="module.expanded" :aria-controls="`${module._id}-content`">
              {{ module.title }}
            </button>
          </h2>
          <div :id="`${module._id}-content`" class="accordion-collapse collapse show" :aria-labelledby="module.title"
               :data-bs-parent="index > 0 ? 'course__accordion' : `course__accordion-${index+1}`">
            <div class="accordion-body">
              <div v-for="lecture in module.sections" :key="lecture.name+index"
                   class="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                <div class="course__curriculum-info">
                  <svg class="document" viewBox="0 0 24 24">
                    <path class="st0" d="M14,2H6C4.9,2,4,2.9,4,4v16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8L14,2z"/>
                    <polyline class="st0" points="14,2 14,8 20,8 "/>
                    <line class="st0" x1="16" y1="13" x2="8" y2="13"/>
                    <line class="st0" x1="16" y1="17" x2="8" y2="17"/>
                    <polyline class="st0" points="10,9 9,9 8,9 "/>
                  </svg>
                  <h3><!--<span>Reading:</span>--> {{ lecture.title }}</h3>
                </div>
                <div class="course__curriculum-meta">
                  <span class="time"> <i class="fa fa-clock"></i> {{ lecture.duration }} min</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Course} from "@/core/domain/entities/course.entity";
import {useStore} from 'vuex';
import {key} from '@/infrastructure/di/store';
import {LecturesUseCase} from '@/core/usecases/lectures.usecase';
import {computed, ComputedRef, defineComponent, PropType} from 'vue';
import {User} from '@/core/domain/entities/user.entity';
import {RoleConstants} from '@/core/domain/constants/role.constants';

export default defineComponent({
  name: "curriculumTab",
  computed: {
    RoleConstants() {
      return RoleConstants
    }
  },
  props: {
    course: {
      type: Object as PropType<Course>,
      required: true
    },
  },
  setup() {
    const store = useStore(key);
    const lecturesUseCase = new LecturesUseCase(store);

    const user: ComputedRef<User> = computed(() => store.getters.user);

    return {
      store,
      lecturesUseCase,
      user
    }
  },
  // data() {
  // },
  methods: {
    getLectures() {
      //this.lecturesUseCase.getLectures(this.$props.course?.courseId);
    }
  },
  created() {
    this.getLectures();
  }
});
</script>
