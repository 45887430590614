import {InstructorRepository} from "@/infrastructure/data/repositories/instructor.repository.impl";
import {IInfoProducerRepository} from "@/core/domain/repositories/inforproducer.repository";
import {InstructorEntity} from "@/core/domain/entities/instructor.entity";

export class InfoProducerUseCase {
    private _repo: IInfoProducerRepository;

    constructor() {
        this._repo = new InstructorRepository();
    }

    async getInstructorInfo(userId: string): Promise<InstructorEntity> {
        return await this._repo.getInstructorInfo(userId);
    }
}