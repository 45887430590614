import {IInfoProducerRepository} from "@/core/domain/repositories/inforproducer.repository";
import {InstructorEntity} from "@/core/domain/entities/instructor.entity";
import {IInstructorDatasource} from "@/core/domain/datasources/instructor.datasource";
import {InstructorDatasource} from "@/infrastructure/data/datasources/instructor.datasource.impl";

class InstructorRepository implements IInfoProducerRepository {
    private _datasSource: IInstructorDatasource;

    constructor() {
        this._datasSource = new InstructorDatasource()
    }

    async  getInstructorInfo(userId: string): Promise<InstructorEntity>{
        return await this._datasSource.getInstructorInfo(userId);
    }


}

export {
    InstructorRepository
}