import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/img/page-title/page-title-shape-1.png'
import _imports_1 from '@/assets/img/page-title/page-title-shape-6.png'
import _imports_2 from '@/assets/img/page-title/page-title-shape-4.png'
import _imports_3 from '@/assets/img/shape/yellow-bg.png'


const _hoisted_1 = { class: "page__title-area pt-90 pb-90" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-xxl-8 col-xl-8 col-lg-8" }
const _hoisted_5 = { class: "course__wrapper" }
const _hoisted_6 = { class: "page__title-content mb-25" }
const _hoisted_7 = { class: "page__title-breadcrumb" }
const _hoisted_8 = { "aria-label": "breadcrumb" }
const _hoisted_9 = { class: "breadcrumb" }
const _hoisted_10 = { class: "breadcrumb-item" }
const _hoisted_11 = { class: "breadcrumb-item" }
const _hoisted_12 = {
  class: "breadcrumb-item active",
  "aria-current": "page"
}
const _hoisted_13 = { class: "page__title-pre" }
const _hoisted_14 = { class: "page__title-3" }
const _hoisted_15 = { class: "course__meta-2 d-sm-flex mb-30" }
const _hoisted_16 = { class: "course__teacher-3 d-flex align-items-center mr-70 mb-30" }
const _hoisted_17 = {
  key: 0,
  class: "course__teacher-thumb-3 mr-15"
}
const _hoisted_18 = ["src"]
const _hoisted_19 = { class: "course__teacher-info-3" }
const _hoisted_20 = {
  key: 0,
  class: "course__update mr-80 mb-30"
}
const _hoisted_21 = { class: "course__rating-2 mb-30" }
const _hoisted_22 = { class: "course__rating-inner d-flex align-items-center" }
const _hoisted_23 = { class: "course__img mb-30" }
const _hoisted_24 = ["src"]
const _hoisted_25 = { class: "course__tab-2 mb-45" }
const _hoisted_26 = {
  class: "nav nav-tabs",
  id: "courseTab",
  role: "tablist"
}
const _hoisted_27 = {
  class: "nav-item",
  role: "presentation"
}
const _hoisted_28 = {
  class: "nav-link active",
  id: "description-tab",
  "data-bs-toggle": "tab",
  "data-bs-target": "#description",
  type: "button",
  role: "tab",
  "aria-controls": "description",
  "aria-selected": "true"
}
const _hoisted_29 = {
  class: "nav-item",
  role: "presentation"
}
const _hoisted_30 = {
  class: "nav-link",
  id: "curriculum-tab",
  "data-bs-toggle": "tab",
  "data-bs-target": "#curriculum",
  type: "button",
  role: "tab",
  "aria-controls": "curriculum",
  "aria-selected": "false"
}
const _hoisted_31 = { class: "course__tab-content mb-95" }
const _hoisted_32 = {
  class: "tab-content",
  id: "courseTabContent"
}
const _hoisted_33 = {
  class: "tab-pane fade show active",
  id: "description",
  role: "tabpanel",
  "aria-labelledby": "description-tab"
}
const _hoisted_34 = { class: "course__description" }
const _hoisted_35 = { class: "course__tag-2 mb-35 mt-35" }
const _hoisted_36 = { href: "#" }
const _hoisted_37 = {
  key: 0,
  class: "course__description-list mb-45"
}
const _hoisted_38 = { class: "course__related" }
const _hoisted_39 = { class: "row" }
const _hoisted_40 = { class: "col-xxl-12" }
const _hoisted_41 = { class: "section__title-wrapper mb-40" }
const _hoisted_42 = { class: "section__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CurriculumTab = _resolveComponent("CurriculumTab")!
  const _component_ReviewTab = _resolveComponent("ReviewTab")!
  const _component_SliderCourses = _resolveComponent("SliderCourses")!
  const _component_CourseSidebar = _resolveComponent("CourseSidebar")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[14] || (_cache[14] = _createElementVNode("div", { class: "page__title-shape" }, [
      _createElementVNode("img", {
        class: "page-title-shape-5 d-none d-sm-block",
        src: _imports_0,
        alt: ""
      }),
      _createElementVNode("img", {
        class: "page-title-shape-6",
        src: _imports_1,
        alt: ""
      }),
      _createElementVNode("img", {
        class: "page-title-shape-7",
        src: _imports_2,
        alt: ""
      })
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("nav", _hoisted_8, [
                  _createElementVNode("ol", _hoisted_9, [
                    _createElementVNode("li", _hoisted_10, [
                      _createVNode(_component_router_link, { to: "/" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('menu.home')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("li", _hoisted_11, [
                      _createVNode(_component_router_link, { to: "/courses" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('header.courses')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("li", _hoisted_12, _toDisplayString(_ctx.course?.detail?.title), 1)
                  ])
                ])
              ]),
              _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.course?.detail?.title || 'General'), 1),
              _createElementVNode("h5", _hoisted_14, _toDisplayString(_ctx.course?.detail?.title), 1)
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                (_ctx.course.userProfilePicture)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      _createElementVNode("img", {
                        src: _ctx.course.userProfilePicture,
                        alt: "user-image"
                      }, null, 8, _hoisted_18)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("h5", null, _toDisplayString(_ctx.$t('infoproducer.name')), 1),
                  _createElementVNode("p", null, [
                    _createVNode(_component_router_link, {
                      to: `/instructor-details?uid=${_ctx.course.teacherId}`
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.course.teacherName), 1)
                      ]),
                      _: 1
                    }, 8, ["to"])
                  ])
                ])
              ]),
              (_ctx.course?.updatedAt)
                ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                    _createElementVNode("h5", null, _toDisplayString(_ctx.$t('course.lastUpdate')) + ":", 1),
                    _createElementVNode("p", null, _toDisplayString(new Date(_ctx.course?.updatedAt).toLocaleDateString('en-US', {
                           year: 'numeric', month: 'long',
                           day: 'numeric' })), 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_21, [
                _cache[3] || (_cache[3] = _createElementVNode("h5", null, "Review:", -1)),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("ul", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Math.round(_ctx.course?.rating || 5), (star) => {
                      return (_openBlock(), _createElementBlock("li", { key: star }, _cache[2] || (_cache[2] = [
                        _createElementVNode("a", { href: "#" }, [
                          _createElementVNode("i", { class: "fas fa-star" })
                        ], -1)
                      ])))
                    }), 128))
                  ]),
                  _createElementVNode("p", null, _toDisplayString(_ctx.course?.rating || 5), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_23, [
              (_ctx.course.coverLinks)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _ctx.imageFileLink ?? '@/assets/img/course/details/course-details-1.jpg',
                    alt: "",
                    style: {"max-width":"36rem"}
                  }, null, 8, _hoisted_24))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("ul", _hoisted_26, [
                _createElementVNode("li", _hoisted_27, [
                  _createElementVNode("button", _hoisted_28, [
                    _cache[4] || (_cache[4] = _createElementVNode("i", { class: "icon_ribbon_alt" }, null, -1)),
                    _cache[5] || (_cache[5] = _createTextVNode()),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("course.description")), 1)
                  ])
                ]),
                _createElementVNode("li", _hoisted_29, [
                  _createElementVNode("button", _hoisted_30, [
                    _cache[6] || (_cache[6] = _createElementVNode("i", { class: "icon_book_alt" }, null, -1)),
                    _cache[7] || (_cache[7] = _createTextVNode()),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("course.curriculum")), 1)
                  ])
                ]),
                _cache[8] || (_cache[8] = _createElementVNode("li", {
                  class: "nav-item",
                  role: "presentation"
                }, [
                  _createElementVNode("button", {
                    class: "nav-link",
                    id: "review-tab",
                    "data-bs-toggle": "tab",
                    "data-bs-target": "#review",
                    type: "button",
                    role: "tab",
                    "aria-controls": "review",
                    "aria-selected": "false"
                  }, [
                    _createElementVNode("i", { class: "icon_star_alt" }),
                    _createTextVNode(),
                    _createElementVNode("span", null, "Reviews")
                  ])
                ], -1))
              ])
            ]),
            _createElementVNode("div", _hoisted_31, [
              _createElementVNode("div", _hoisted_32, [
                _createElementVNode("div", _hoisted_33, [
                  _createElementVNode("div", _hoisted_34, [
                    _createElementVNode("h3", null, _toDisplayString(_ctx.$t('courseDetail.courseOverview')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.course?.detail?.description), 1),
                    _createElementVNode("div", _hoisted_35, [
                      _cache[9] || (_cache[9] = _createElementVNode("i", { class: "fal fa-tag" }, null, -1)),
                      _createElementVNode("a", _hoisted_36, _toDisplayString(_ctx.course?.categories?.length > 0 ? _ctx.course.categories[0] : ''), 1)
                    ]),
                    _createElementVNode("h4", null, _toDisplayString(_ctx.$t('courseDetail.whatIsTheTarget')), 1),
                    (_ctx.course?.targets?.length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                          _createElementVNode("ul", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.course.targets, (target, index) => {
                              return (_openBlock(), _createElementBlock("li", { key: index }, [
                                _cache[10] || (_cache[10] = _createElementVNode("i", { class: "fas fa-check" }, null, -1)),
                                _createTextVNode(" " + _toDisplayString(target.description), 1)
                              ]))
                            }), 128))
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    _cache[11] || (_cache[11] = _createElementVNode("div", { class: "course__instructor mb-45" }, null, -1))
                  ])
                ]),
                _createVNode(_component_CurriculumTab, {
                  course: _ctx.course,
                  "onUpdate:course": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.course) = $event))
                }, null, 8, ["course"]),
                _createVNode(_component_ReviewTab, {
                  course: _ctx.course,
                  "onUpdate:course": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.course) = $event))
                }, null, 8, ["course"])
              ])
            ]),
            _createElementVNode("div", _hoisted_38, [
              _createElementVNode("div", _hoisted_39, [
                _createElementVNode("div", _hoisted_40, [
                  _createElementVNode("div", _hoisted_41, [
                    _createElementVNode("h2", _hoisted_42, [
                      _createTextVNode(_toDisplayString(_ctx.$t('course.related')), 1),
                      _cache[12] || (_cache[12] = _createElementVNode("span", { class: "yellow-bg yellow-bg-big" }, [
                        _createElementVNode("img", {
                          src: _imports_3,
                          alt: ""
                        })
                      ], -1))
                    ]),
                    _cache[13] || (_cache[13] = _createElementVNode("p", null, null, -1))
                  ])
                ])
              ]),
              _createVNode(_component_SliderCourses, {
                category: _ctx.course?.categories?.length > 0 ? _ctx.course.categories[0] : ''
              }, null, 8, ["category"])
            ])
          ])
        ]),
        _createVNode(_component_CourseSidebar, { course: _ctx.course }, null, 8, ["course"])
      ])
    ])
  ]))
}