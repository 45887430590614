<template>
   <div class="row">
      <div class="col-xxl-12">
         <div class="course__slider swiper-container pb-60">
            <swiper ref="mySwiper" class="pb-50" :space-between="25" :slides-per-view="2" :modules="modules"
               :pagination="{ clickable: true }" :loop="true" :breakpoints="breakpoints">
               <!-- Envolver v-for con un v-if -->
               <template v-if="courseItems.length > 0">
                  <swiper-slide v-for="course in courseItems.slice(0, 4)" :key="course.courseId">
                     <div class="course__item course__item-3 swiper-slide white-bg mb-30 fix">
                        <div class="course__thumb w-img p-relative fix">
                           <router-link :to="`/course-details/${course.courseId}`">
                              <img :src="course.coverLinks?.thumbnailLink || '/default-thumbnail.png'"
                                 alt="Course Thumbnail">
                           </router-link>
                           <div class="course__tag">
                              <a href="#" class="green">{{ course.categories[0] || 'Unknown Category' }}</a>
                           </div>
                        </div>
                        <div class="course__content">
                           <div class="course__meta d-flex align-items-center justify-content-between">
                              <div class="course__lesson">
                                 <span><i class="far fa-book-alt"></i>{{ course.lessons || 0 }} Lessons</span>
                              </div>
                              <div class="course__rating">
                                 <span><i class="fas fa-star"></i>{{ course.rating || 5 }} ({{ course.reviews || 5
                                    }})</span>
                              </div>
                           </div>
                           <h3 class="course__title">
                              <router-link :to="`/course-details/${course.courseId}`">{{ course?.detail?.name
                                 }}</router-link>
                           </h3>
                           <div class="course__teacher d-flex align-items-center">
                              <div class="course__teacher-thumb mr-15">
                                 <img :src="course.userProfilePicture || '/default-teacher.png'"
                                    alt="Instructor Thumbnail">
                              </div>
                              <h6><router-link to="/instructor-details">{{ course.teacherName || 'Unknown Instructor'
                                    }}</router-link></h6>
                           </div>
                        </div>
                        <div class="course__more d-flex justify-content-between align-items-center">
                           <div class="course__status d-flex align-items-center">
                              <span class="green">${{ course.priceUSD || 0 }}</span>
                              <span class="old-price">${{ course.oldPrice || '' }}</span>
                           </div>
                           <!-- <div class="course__btn">
                              <router-link :to="`/course-details/${course.courseId}`" class="link-btn">
                                 Know Details
                                 <i class="far fa-arrow-right"></i>
                                 <i class="far fa-arrow-right"></i>
                              </router-link>
                           </div> -->
                        </div>
                     </div>
                  </swiper-slide>
               </template>
               <!-- Mostrar mensaje si no hay cursos -->
               <p v-else>No courses available at the moment.</p>
            </swiper>
            <!-- Add Pagination -->
            <div class="swiper-pagination"></div>
         </div>
      </div>
   </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper";
import { CoursesUseCase } from "@/core/usecases/courses.usecase";

export default {
   name: 'SliderCourses',
   components: {
      Swiper,
      SwiperSlide,
   },
   props: {
      category: {
         type: String,
         required: false,
         default: '',
      },
   },
   data() {
      return {
         courseItems: [],
         breakpoints: {
            550: { slidesPerView: 1 },
            768: { slidesPerView: 2 },
            992: { slidesPerView: 2 },
         },
      };
   },
   watch: {
      async category(newVal) {
         try {
            const coursesUseCase = new CoursesUseCase(this.$store);
            const language = this.$i18n.locale || 'en';

            const paginatedCourses = await coursesUseCase.getAvailable(1, 30, language, newVal);
            this.courseItems = paginatedCourses?.data || [];
         } catch (error) {
            console.error("Error fetching courses:", error);
            this.courseItems = [];
         }
      }
   },
   setup() {
      return {
         modules: [Pagination],
      };
   },

};
</script>
