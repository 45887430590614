<template>
   <section class="about__area pt-90 pb-150 relative text-center">
      <div class="container relative">

         <div class="row justify-content-center">
            <div class="col-12 col-md-9">
               <div class="about__content">
                  <img src="@/assets/img/logo/bullfy.png" alt="" class="img">

                  <div class="section__title-wrapper mb-25">

                    <h2 class="section__title slide-in w-100">
                      {{ $t('about.whatis') }}
                      <span class="yellow-bg-big">
                           Bullfy
                           <img src="@/assets/img/shape/yellow-bg-2.png" alt="">
                        </span>
                    </h2>

                     <h3 class="mr slide-in">{{ $t('about.networkOfEntre') }}</h3>
                     <p class="slide-in">{{ $t('about.weAreNetwork') }}</p>
                     <h3 class="slide-in">{{ $t('about.outHistory') }}</h3>
                     <p class="slide-in">{{ $t('about.bullfyWas') }}</p>
                     <h3 class="slide-in">{{ $t('about.whyBullfy') }}</h3>
                     <p class="slide-in">{{ $t('about.weRealized') }}</p>
                     <h3 class="slide-in">{{ $t('about.ourHome') }}</h3>
                     <p class="slide-in">{{ $t('about.atBullfy') }}</p>
                     <h3 class="slide-in">{{ $t('about.ourTeam') }}</h3>
                     <p class="slide-in">{{ $t('about.atBullfyWe') }}</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
</template>

<script>
export default {
   name: 'AboutArea'
};
</script>


<style scoped>
.img {
   margin-bottom: 80px;
}

.mr {
   margin-top: 30px;
}

@keyframes slideInFromLeft {
   0% {
      transform: translateX(-100%);
      opacity: 0;
   }
   100% {
      transform: translateX(0);
      opacity: 1;
   }
}

@keyframes slideInFromRight {
   0% {
      transform: translateX(100%);
      opacity: 0;
   }
   100% {
      transform: translateX(0);
      opacity: 1;
   }
}

.slide-in {
   display: inline-block;
   animation-duration: 0.8s;
   animation-fill-mode: both;
   animation-timing-function: ease-out;
   opacity: 0;
}

.slide-in:nth-child(even) {
   animation-name: slideInFromRight;
}

.slide-in:nth-child(odd) {
   animation-name: slideInFromLeft;
}

.slide-in:nth-child(1) {
   animation-delay: 0.1s;
}
.slide-in:nth-child(2) {
   animation-delay: 0.2s;
}
.slide-in:nth-child(3) {
   animation-delay: 0.3s;
}
.slide-in:nth-child(4) {
   animation-delay: 0.4s;
}
.slide-in:nth-child(5) {
   animation-delay: 0.5s;
}
</style>

