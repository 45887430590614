import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/img/page-title/page-title-shape-1.png'
import _imports_1 from '@/assets/img/page-title/page-title-shape-6.png'
import _imports_2 from '@/assets/img/page-title/page-title-shape-3.png'
import _imports_3 from '@/assets/img/page-title/page-title-shape-4.png'
import _imports_4 from '@/assets/img/teacher/details/shape/shape-1.png'
import _imports_5 from '@/assets/img/teacher/details/shape/shape-2.png'
import _imports_6 from '@/assets/img/shape/yellow-bg.png'


const _hoisted_1 = { class: "teacher__area pt-100 pb-110" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-xxl-4 col-xl-4 col-lg-4 col-md-6" }
const _hoisted_5 = { class: "teacher__details-thumb p-relative w-img pr-30" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "col-xxl-8 col-xl-8 col-lg-8" }
const _hoisted_8 = { class: "teacher__wrapper" }
const _hoisted_9 = { class: "teacher__top d-md-flex align-items-end justify-content-between" }
const _hoisted_10 = { class: "teacher__info" }
const _hoisted_11 = { class: "teacher__social-2" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = ["href"]
const _hoisted_14 = { key: 1 }
const _hoisted_15 = ["href"]
const _hoisted_16 = { class: "teacher__bio" }
const _hoisted_17 = { class: "teacher__courses pt-55" }
const _hoisted_18 = { class: "teacher__course-wrapper" }
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "course__item white-bg mb-30 fix" }
const _hoisted_21 = { class: "course__thumb w-img p-relative fix" }
const _hoisted_22 = ["src"]
const _hoisted_23 = { class: "course__tag" }
const _hoisted_24 = {
  href: "#",
  class: "green"
}
const _hoisted_25 = { class: "course__content" }
const _hoisted_26 = { class: "course__title" }
const _hoisted_27 = { class: "course__teacher d-flex align-items-center" }
const _hoisted_28 = { class: "course__teacher-thumb mr-15" }
const _hoisted_29 = ["src"]
const _hoisted_30 = { href: "#" }
const _hoisted_31 = { class: "course__more d-flex justify-content-between align-items-center" }
const _hoisted_32 = { class: "course__status d-flex align-items-center" }
const _hoisted_33 = { class: "course__btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[11] || (_cache[11] = _createStaticVNode("<div class=\"page__title-shape\"><img class=\"page-title-shape-5 d-none d-sm-block\" src=\"" + _imports_0 + "\" alt=\"\"><img class=\"page-title-shape-6\" src=\"" + _imports_1 + "\" alt=\"\"><img class=\"page-title-shape-3\" src=\"" + _imports_2 + "\" alt=\"\"><img class=\"page-title-shape-7\" src=\"" + _imports_3 + "\" alt=\"\"></div>", 1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              src: _ctx.instructor?.coverLink?.fileLink,
              alt: ""
            }, null, 8, _hoisted_6),
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "teacher__details-shape" }, [
              _createElementVNode("img", {
                class: "teacher-details-shape-1",
                src: _imports_4,
                alt: ""
              }),
              _createElementVNode("img", {
                class: "teacher-details-shape-2",
                src: _imports_5,
                alt: ""
              })
            ], -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("h4", null, _toDisplayString(_ctx.instructor.fullName), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.instructor.jobTitle), 1)
              ]),
              _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"teacher__rating\"><h5>Review:</h5><div class=\"teacher__rating-inner d-flex align-items-center\"><ul><li><a href=\"#\"><i class=\"fas fa-star\"></i></a></li><li><a href=\"#\"><i class=\"fas fa-star\"></i></a></li><li><a href=\"#\"><i class=\"fas fa-star\"></i></a></li><li><a href=\"#\"><i class=\"fas fa-star\"></i></a></li><li><a href=\"#\"><i class=\"fas fa-star\"></i></a></li></ul><p>4.5</p></div></div>", 1)),
              _createElementVNode("div", _hoisted_11, [
                _cache[3] || (_cache[3] = _createElementVNode("h4", null, "Follow Me:", -1)),
                _createElementVNode("ul", null, [
                  (_ctx.instructor.facebook)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_12, [
                        _createElementVNode("a", {
                          href: `https://www.facebook.com/${_ctx.instructor.facebook}`,
                          target: "_blank",
                          rel: "noopener noreferrer"
                        }, _cache[1] || (_cache[1] = [
                          _createElementVNode("i", { class: "fab fa-facebook-f" }, null, -1)
                        ]), 8, _hoisted_13)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.instructor.instagram)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_14, [
                        _createElementVNode("a", {
                          href: `https://www.instagram.com/${_ctx.instructor.instagram}`,
                          target: "_blank",
                          rel: "noopener noreferrer"
                        }, _cache[2] || (_cache[2] = [
                          _createElementVNode("i", { class: "fab fa-instagram ms-2" }, null, -1)
                        ]), 8, _hoisted_15)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "teacher__follow mb-5" }, null, -1))
            ]),
            _createElementVNode("div", _hoisted_16, [
              _cache[6] || (_cache[6] = _createElementVNode("h3", null, "Short Bio", -1)),
              _createElementVNode("p", null, _toDisplayString(_ctx.instructor.biography), 1)
            ]),
            _createElementVNode("div", _hoisted_17, [
              _cache[10] || (_cache[10] = _createElementVNode("div", { class: "section__title-wrapper mb-30" }, [
                _createElementVNode("h2", { class: "section__title" }, [
                  _createTextVNode("Teacher "),
                  _createElementVNode("span", { class: "yellow-bg yellow-bg-big" }, [
                    _createTextVNode("Course"),
                    _createElementVNode("img", {
                      src: _imports_6,
                      alt: ""
                    })
                  ])
                ])
              ], -1)),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courses, (course) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: course.courseId,
                      class: "col-xxl-6 col-xl-6 col-lg-6 col-md-6"
                    }, [
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("div", _hoisted_21, [
                          _createVNode(_component_router_link, {
                            to: `/course-details/${course.courseId}`
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("img", {
                                src: course.coverLinks?.thumbnailLink,
                                alt: ""
                              }, null, 8, _hoisted_22)
                            ]),
                            _: 2
                          }, 1032, ["to"]),
                          _createElementVNode("div", _hoisted_23, [
                            _createElementVNode("a", _hoisted_24, _toDisplayString(course.category || 'Marketing'), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_25, [
                          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "course__meta d-flex align-items-center justify-content-between" }, null, -1)),
                          _createElementVNode("h3", _hoisted_26, [
                            _createVNode(_component_router_link, {
                              to: `/course-details/${course.courseId}`
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(course?.detail?.name), 1)
                              ]),
                              _: 2
                            }, 1032, ["to"])
                          ]),
                          _createElementVNode("div", _hoisted_27, [
                            _createElementVNode("div", _hoisted_28, [
                              _createElementVNode("img", {
                                src: _ctx.instructor?.coverLink?.thumbnailLink,
                                alt: ""
                              }, null, 8, _hoisted_29)
                            ]),
                            _createElementVNode("h6", null, [
                              _createElementVNode("a", _hoisted_30, _toDisplayString(_ctx.instructor?.fullName), 1)
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_31, [
                          _createElementVNode("div", _hoisted_32, [
                            _createElementVNode("span", {
                              class: _normalizeClass(_ctx.green)
                            }, "$" + _toDisplayString(course.priceUSD), 3)
                          ]),
                          _createElementVNode("div", _hoisted_33, [
                            _createVNode(_component_router_link, {
                              to: `/course-details/${course.courseId}`,
                              class: "link-btn"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('course.watchCourse')) + " ", 1),
                                _cache[8] || (_cache[8] = _createElementVNode("i", { class: "far fa-arrow-right" }, null, -1)),
                                _cache[9] || (_cache[9] = _createElementVNode("i", { class: "far fa-arrow-right" }, null, -1))
                              ]),
                              _: 2
                            }, 1032, ["to"])
                          ])
                        ])
                      ])
                    ]))
                  }), 128))
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}