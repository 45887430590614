import {Course, PaginatedCourses} from '@/core/domain/entities/course.entity';

type CoursesState = {
  instructorCourses: PaginatedCourses;
  courses: PaginatedCourses;
  course: Course;
}

const state: CoursesState = {
  instructorCourses: {data: [], metadata: {page: 1, total: 0, totalPages: 1}} as PaginatedCourses,
  courses: {data: [], metadata: {page: 1, total: 0, totalPages: 1}} as PaginatedCourses,
  course: {} as Course
}

const getters = {
  instructorCourses: (state: CoursesState) => state.instructorCourses,
  courses: (state: CoursesState) => state.courses,
  course: (state: CoursesState) => state.course,
}

const mutations = {
  setInstructorCourses(state: CoursesState, instructorCourses: PaginatedCourses) {
    state.instructorCourses = instructorCourses;
  },
  setCourses(state: CoursesState, courses: PaginatedCourses) {
    state.courses = courses;
  },
  setCourse(state: CoursesState, course: Course) {
    state.course = course;
  }
}

const actions = {
  setInstructorCourses({commit}, payload: PaginatedCourses) {
    commit('setInstructorCourses', payload);
  },
  setCourses({commit}, payload: PaginatedCourses) {
    commit('setCourses', payload);
  },
  setCourse({commit}) {
    commit('setCourse');
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}