<template>
  <div v-for="(course, index) in courses" :key="course.courseId" class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
    <div class="course__item white-bg mb-30 fix">
      <div class="course__thumb w-img p-relative fix max-h">
        <div v-if="course.coverLinks" class=" w-100 min-height-img">
          <img :src="course.coverLinks.fileLink" alt="" class="h-max-img">
        </div>

        <div v-else class="h-max min-height-img"></div>
        <div class="course__tag">
          <!--          <a href="#" :class="course.color">{{ course.category }}</a>-->
          <router-link :to="`/course-details/${course.courseId}`">
            {{ course?.categories && course.categories.length > 0 ? course?.categories[0] : '' }}
          </router-link>
        </div>
      </div>
      <div class="course__content">
        <div class="course__meta d-flex align-items-center justify-content-between">
          <div class="course__lesson">
            <span><i class="far fa-book-alt"></i>{{
              course.detail?.lessons ?? courseItems[index]?.lesson
            }} Modulos</span>
          </div>
          <div class="course__rating">
            <span><i class="icon_star"></i>{{ course.rating }} ({{ course.totalRates }})</span>
          </div>
        </div>
        <h3 class="course__title">
          <router-link :to="`/course-details/${course.courseId}`">
            {{ course.detail?.title }}
          </router-link>
        </h3>
        <div class="course__teacher d-flex align-items-center">
          <div class="course__teacher-thumb mr-15">
            <img :src="course.userProfilePicture ?? require('@/assets/img/course/teacher/teacher.jpg')" alt="">
          </div>
          <h6>

            <router-link :to="`/instructor-details?uid=${course.teacherId}`">
              {{ course.teacherName }}
            </router-link>

          </h6>
        </div>
      </div>
      <div class="course__more d-flex justify-content-between align-items-center">
        <div class="course__status d-flex align-items-center">
          <!--          <span :class="course.color">${{ course.price }}</span>-->
          <span>${{ course.isFree ? 'Free' : course.priceUSD }}</span>
          <!--          <span class="old-price">${{ course.oldPrice }}</span>-->
        </div>
        <div class="course__btn">

          <router-link :to="`/course-details/${course.courseId}`">
            {{ $t('actions.knowMore') }}
            <i class="far fa-arrow-right"></i>
          </router-link>

        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import CourseMixin from '@/mixins/courseItemsMixin';
import { defineComponent, PropType } from 'vue';
import { Courses } from '@/core/domain/entities/course.entity';


export default defineComponent({
  name: 'GridTab',
  mixins: [CourseMixin],
  props: {
    courses: Object as PropType<Courses>
  }
});
</script>

<style>
.max-h {
  max-height: 228px;
}

.min-height-img {
  min-height: 336px;
}

.h-max-img {
  width: 100% !important;
  max-height: auto !important;
}
</style>