import {HttpBase} from '../../di/httpBase';
import {IPaymentsDataSource} from "@/core/domain/datasources/payments.datasource";
import {PaginatedPayment, Payment} from "@/core/domain/entities/payment.entity";

class PaymentsDatasource implements IPaymentsDataSource {
    private _httpBase: HttpBase;

    constructor() {
        this._httpBase = new HttpBase();
    }

    async getPayments(page: number, limit: number, defaultLanguage: string, categoryId?: string): Promise<PaginatedPayment> {
        try {
            const res = (await this._httpBase.get('/payment/available', {page, limit, defaultLanguage, categoryId}));
            return res.data;
        } catch (error) {
            console.error(error)
            throw error;
        }
    }

    async createPayment(payment: Payment): Promise<{
        paymentId: string;
        publicKey: string;
        externalClientSecret: string;
    }> {
        try {
            const response = await this._httpBase.post('/payment/generate-payment', {
               ...payment
            });
            if (response.data.success)
                return response.data.data;
        } catch (error) {
            console.error(error)
            throw error;
        }
        return {paymentId: '', publicKey: '', externalClientSecret: ''};
    }

    async confirmPayment(status: string,
                         externalPaymentId: string,
                         externalClientSecret: string,
                         paymentMethod: string,
                         amountPaid: number): Promise<string> {

        try {
            const response = await this._httpBase.post('/payment/confirm', {
                status, externalPaymentId, externalClientSecret, paymentMethod, amountPaid
            });
            return response.data.paymentId;
        } catch (error) {
            console.error(error)
            throw error;
        }
    }
    async assignCourse(userId: string, courseId: string, totalPaid: number, paymentId: string): Promise<boolean>{
        try {
            const response = await this._httpBase.post('/user-course/assign-course', {
                userId, courseId, totalPaid, paymentId
            });
            return response.data.success
        } catch (error) {
            console.error(error)
            throw error;
        }
    }
}

export {
    PaymentsDatasource
}