import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/img/logo/bullfy.png'


const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row align-items-center" }
const _hoisted_3 = { class: "col-xxl-3 col-xl-3 col-lg-4 col-md-2 col-sm-4 col-6" }
const _hoisted_4 = { class: "header__left d-flex" }
const _hoisted_5 = { class: "logo" }
const _hoisted_6 = {
  key: 0,
  src: _imports_0,
  alt: "logo"
}
const _hoisted_7 = {
  key: 1,
  src: _imports_0,
  alt: "logo"
}
const _hoisted_8 = { class: "col-xxl-9 col-xl-9 col-lg-8 col-md-10 col-sm-8 col-6" }
const _hoisted_9 = { class: "header__right d-flex justify-content-end align-items-center" }
const _hoisted_10 = {
  id: "mobile-menu",
  class: "d-none d-xl-block"
}
const _hoisted_11 = { class: "has-dropdown" }
const _hoisted_12 = { class: "submenu" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = {
  key: 0,
  class: "has-dropdown"
}
const _hoisted_15 = { class: "submenu" }
const _hoisted_16 = { href: "https://consumer.bullfy.com/" }
const _hoisted_17 = {
  key: 0,
  class: "header__search p-relative ml-50 d-none d-md-block"
}
const _hoisted_18 = { class: "header__cart" }
const _hoisted_19 = {
  key: 0,
  class: "cart-item"
}
const _hoisted_20 = {
  key: 1,
  class: "header__btn ml-20 d-none d-sm-block position-relative"
}
const _hoisted_21 = {
  href: "https://consumer.bullfy.com/",
  class: "text-decoration-none text-dark font-weight-bold"
}
const _hoisted_22 = { class: "header__btn ml-20 d-none d-sm-block" }
const _hoisted_23 = {
  key: 0,
  href: "https://consumer.bullfy.com/",
  class: "e-btn"
}
const _hoisted_24 = { class: "sidebar__menu d-xl-none" }
const _hoisted_25 = { class: "cartmini__area" }
const _hoisted_26 = { class: "cartmini__title" }
const _hoisted_27 = { class: "cartmini__widget" }
const _hoisted_28 = { class: "cartmini__inner" }
const _hoisted_29 = { class: "cartmini__thumb" }
const _hoisted_30 = { href: "#" }
const _hoisted_31 = ["src"]
const _hoisted_32 = { class: "cartmini__content" }
const _hoisted_33 = { href: "#" }
const _hoisted_34 = { class: "product-quantity mt-10 mb-10" }
const _hoisted_35 = ["onUpdate:modelValue"]
const _hoisted_36 = { class: "product__sm-price-wrapper" }
const _hoisted_37 = { class: "product__sm-price" }
const _hoisted_38 = ["onClick"]
const _hoisted_39 = { class: "cartmini__checkout" }
const _hoisted_40 = { class: "cartmini__checkout-title mb-30" }
const _hoisted_41 = { class: "cartmini__checkout-btn" }
const _hoisted_42 = { class: "sidebar__wrapper" }
const _hoisted_43 = { class: "sidebar__content" }
const _hoisted_44 = { class: "logo mb-40" }
const _hoisted_45 = { class: "side-info-content sidebar-menu mm-menu" }
const _hoisted_46 = { key: 0 }
const _hoisted_47 = {
  key: 0,
  class: "has-dropdown"
}
const _hoisted_48 = { class: "submenu" }
const _hoisted_49 = {
  key: 0,
  class: "sidebar__search p-relative mt-40"
}
const _hoisted_50 = {
  key: 1,
  class: "sidebar__cart mt-30"
}
const _hoisted_51 = {
  key: 0,
  class: "cart-item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("header", null, [
      _createElementVNode("div", {
        id: "header-sticky",
        class: _normalizeClass(`${_ctx.isSticky ? 'header__area header__transparent header__padding sticky'
      : 'header__area header__transparent header__padding'} ${_ctx.header__white && _ctx.header__white}`)
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_router_link, { to: "/" }, {
                    default: _withCtx(() => [
                      (_ctx.header__white && !_ctx.isSticky)
                        ? (_openBlock(), _createElementBlock("img", _hoisted_6))
                        : (_openBlock(), _createElementBlock("img", _hoisted_7))
                    ]),
                    _: 1
                  })
                ]),
                _cache[15] || (_cache[15] = _createElementVNode("div", { class: "header__category d-none d-lg-block" }, null, -1))
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", {
                  class: _normalizeClass(`main-menu ${_ctx.header__white && 'main-menu-3'}`)
                }, [
                  _createElementVNode("nav", _hoisted_10, [
                    _createElementVNode("ul", null, [
                      _createElementVNode("li", null, [
                        _createVNode(_component_router_link, { to: "/" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('menu.home')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("li", null, [
                        _createVNode(_component_router_link, { to: "/about" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('header.about')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("li", _hoisted_11, [
                        _createVNode(_component_router_link, { to: "/courses" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('header.courses')), 1)
                          ]),
                          _: 1
                        }),
                        _createElementVNode("ul", _hoisted_12, [
                          _createElementVNode("li", null, [
                            _createVNode(_component_router_link, { to: "/courses" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('header.courses')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          (!_ctx.isAuthenticated)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_13, [
                                _createVNode(_component_router_link, { to: "/instructor-register" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('footer.becomeTeacher')), 1)
                                  ]),
                                  _: 1
                                })
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      (_ctx.isAuthenticated)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_14, [
                            _createVNode(_component_router_link, { to: "/courses" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('menu.profile')), 1)
                              ]),
                              _: 1
                            }),
                            _createElementVNode("ul", _hoisted_15, [
                              _createElementVNode("li", null, [
                                _createElementVNode("a", _hoisted_16, _toDisplayString(_ctx.$t('menu.myCourses')), 1)
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logOut && _ctx.logOut(...args)))
                                }, _toDisplayString(_ctx.$t('menu.logOut')), 1)
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ], 2),
                (_ctx.showSearch)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      _cache[17] || (_cache[17] = _createElementVNode("form", { action: "#" }, [
                        _createElementVNode("input", {
                          type: "text",
                          placeholder: "Search..."
                        }),
                        _createElementVNode("button", { type: "submit" }, [
                          _createElementVNode("i", { class: "fad fa-search" })
                        ])
                      ], -1)),
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("a", {
                          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleMiniCart && _ctx.toggleMiniCart(...args))),
                          class: "cart-toggle-btn"
                        }, [
                          _cache[16] || (_cache[16] = _createStaticVNode("<div class=\"header__cart-icon\"><svg viewBox=\"0 0 24 24\"><circle class=\"st0\" cx=\"9\" cy=\"21\" r=\"1\"></circle><circle class=\"st0\" cx=\"20\" cy=\"21\" r=\"1\"></circle><path class=\"st0\" d=\"M1,1h4l2.7,13.4c0.2,1,1,1.6,2,1.6h9.7c1,0,1.8-0.7,2-1.6L23,6H6\"></path></svg></div>", 1)),
                          (_ctx.totalItems > 0)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_ctx.totalItems), 1))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                false
                  ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                      _createElementVNode("a", _hoisted_21, [
                        _createTextVNode(_toDisplayString(_ctx.$t('header.teachersSignIn')) + " ", 1),
                        _cache[18] || (_cache[18] = _createElementVNode("span", { class: "border-bottom position-absolute bottom-0 left-0 w-100 border border-dark" }, " ", -1))
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_22, [
                  (!_ctx.isAuthenticated)
                    ? (_openBlock(), _createElementBlock("a", _hoisted_23, _toDisplayString(_ctx.$t('actions.startNow')), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("div", {
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleSidebar && _ctx.handleSidebar(...args))),
                    class: "sidebar-toggle-btn ml-30",
                    id: "sidebar-toggle"
                  }, _cache[19] || (_cache[19] = [
                    _createElementVNode("span", { class: "line" }, null, -1),
                    _createElementVNode("span", { class: "line" }, null, -1),
                    _createElementVNode("span", { class: "line" }, null, -1)
                  ]))
                ])
              ])
            ])
          ])
        ])
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_25, [
      _createElementVNode("div", {
        class: _normalizeClass(`${_ctx.showMiniCart ? 'cartmini__wrapper opened' : 'cartmini__wrapper'}`)
      }, [
        _createElementVNode("div", _hoisted_26, [
          _createElementVNode("h4", null, _toDisplayString(_ctx.$t('shoppingCart.title')), 1)
        ]),
        _createElementVNode("div", {
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleCloseMiniCart && _ctx.handleCloseMiniCart(...args))),
          class: "cartmini__close"
        }, _cache[20] || (_cache[20] = [
          _createElementVNode("button", {
            type: "button",
            class: "cartmini__close-btn"
          }, [
            _createElementVNode("i", { class: "fal fa-times" })
          ], -1)
        ])),
        _createElementVNode("div", _hoisted_27, [
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: item.courseId
                }, [
                  _createElementVNode("div", _hoisted_29, [
                    _createElementVNode("a", _hoisted_30, [
                      (item.linkImage)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: item.linkImage,
                            alt: ""
                          }, null, 8, _hoisted_31))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_32, [
                    _createElementVNode("h5", null, [
                      _createElementVNode("a", _hoisted_33, _toDisplayString(item?.courseName), 1)
                    ]),
                    _createElementVNode("div", _hoisted_34, [
                      _createElementVNode("span", {
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleDecreaseValue('valueOne'))),
                        class: "cart-minus"
                      }, "-"),
                      _withDirectives(_createElementVNode("input", {
                        class: "cart-input",
                        type: "text",
                        "onUpdate:modelValue": ($event: any) => ((item.quantity) = $event)
                      }, null, 8, _hoisted_35), [
                        [_vModelText, item.quantity]
                      ]),
                      _createElementVNode("span", {
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleIncreaseValue('valueOne'))),
                        class: "cart-plus"
                      }, "+")
                    ]),
                    _createElementVNode("div", _hoisted_36, [
                      _createElementVNode("span", _hoisted_37, "$" + _toDisplayString(item?.price?.toFixed(2)), 1)
                    ])
                  ]),
                  _createElementVNode("a", {
                    href: "#",
                    onClick: _withModifiers(($event: any) => (_ctx.removeItem(item.courseId)), ["prevent"]),
                    class: "cartmini__del"
                  }, _cache[21] || (_cache[21] = [
                    _createElementVNode("i", { class: "fal fa-times" }, null, -1)
                  ]), 8, _hoisted_38)
                ]))
              }), 128))
            ])
          ]),
          _createElementVNode("div", _hoisted_39, [
            _createElementVNode("div", _hoisted_40, [
              _cache[22] || (_cache[22] = _createElementVNode("h4", null, "Subtotal:", -1)),
              _createElementVNode("span", null, "$" + _toDisplayString(_ctx.formattedCartTotal), 1)
            ]),
            _createElementVNode("div", _hoisted_41, [
              _createVNode(_component_router_link, {
                to: "/cart",
                class: "e-btn e-btn-border mb-10 w-100"
              }, {
                default: _withCtx(() => [
                  _cache[23] || (_cache[23] = _createElementVNode("span", null, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('shoppingCart.viewCart')), 1)
                ]),
                _: 1
              }),
              _createElementVNode("button", {
                class: "e-btn w-100",
                onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.gotoPay && _ctx.gotoPay(...args)))
              }, _toDisplayString(_ctx.$t('shoppingCart.checkout')), 1)
            ])
          ])
        ])
      ], 2)
    ]),
    _createElementVNode("div", {
      onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.toggleMiniCart && _ctx.toggleMiniCart(...args))),
      class: _normalizeClass([_ctx.showMiniCart ? 'body-overlay opened' : 'body-overlay'])
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass(`${_ctx.showSidebar ? 'sidebar__area sidebar-opened' : 'sidebar__area'}`)
    }, [
      _createElementVNode("div", _hoisted_42, [
        _createElementVNode("div", {
          onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.handleSidebarClose && _ctx.handleSidebarClose(...args))),
          class: "sidebar__close"
        }, _cache[24] || (_cache[24] = [
          _createElementVNode("button", {
            class: "sidebar__close-btn",
            id: "sidebar__close-btn"
          }, [
            _createElementVNode("span", null, [
              _createElementVNode("i", { class: "fal fa-times" })
            ]),
            _createElementVNode("span", null, "close")
          ], -1)
        ])),
        _createElementVNode("div", _hoisted_43, [
          _createElementVNode("div", _hoisted_44, [
            _createVNode(_component_router_link, { to: "/" }, {
              default: _withCtx(() => _cache[25] || (_cache[25] = [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: "logo"
                }, null, -1)
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_45, [
            _createElementVNode("ul", null, [
              _createElementVNode("li", {
                class: _normalizeClass(["menu-item-has-children has-droupdown", [_ctx.menuOption.homeDropdown === true ? 'active' : '']])
              }, [
                _createElementVNode("a", {
                  onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.menuOption.homeDropdown = !_ctx.menuOption.homeDropdown))
                }, _toDisplayString(_ctx.$t('menu.home')), 1),
                _createElementVNode("ul", {
                  class: _normalizeClass(["sub-menu", [_ctx.menuOption.homeDropdown === true ? 'active' : '',]])
                }, [
                  _createElementVNode("li", null, [
                    _createVNode(_component_router_link, { to: "/home" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('menu.home')), 1)
                      ]),
                      _: 1
                    })
                  ])
                ], 2)
              ], 2),
              _createElementVNode("li", {
                class: _normalizeClass(["menu-item-has-children has-droupdown", [_ctx.menuOption.homeDropdown === true ? 'active' : '']])
              }, [
                _createElementVNode("a", {
                  onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.menuOption.homeDropdown = !_ctx.menuOption.homeDropdown))
                }, _toDisplayString('About me')),
                _createElementVNode("ul", {
                  class: _normalizeClass(["sub-menu", [_ctx.menuOption.homeDropdown === true ? 'active' : '',]])
                }, [
                  _createElementVNode("li", null, [
                    _createVNode(_component_router_link, { to: "/about" }, {
                      default: _withCtx(() => _cache[26] || (_cache[26] = [
                        _createTextVNode(_toDisplayString('About me'))
                      ])),
                      _: 1
                    })
                  ])
                ], 2)
              ], 2),
              _createElementVNode("li", {
                class: _normalizeClass(["menu-item-has-children has-droupdown", [_ctx.menuOption.coursesDropdown === true ? 'active' : '',]])
              }, [
                _createElementVNode("a", {
                  onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.menuOption.coursesDropdown = !_ctx.menuOption.coursesDropdown))
                }, "Courses"),
                _createElementVNode("ul", {
                  class: _normalizeClass(["sub-menu", [_ctx.menuOption.coursesDropdown === true ? 'active' : '',]])
                }, [
                  _createElementVNode("li", null, [
                    _createVNode(_component_router_link, { to: "/courses" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('header.courses')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  (!_ctx.isAuthenticated)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_46, [
                        _createVNode(_component_router_link, { to: "/instructor-register" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('footer.becomeTeacher')), 1)
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true)
                ], 2)
              ], 2),
              (_ctx.isAuthenticated)
                ? (_openBlock(), _createElementBlock("li", _hoisted_47, [
                    _createVNode(_component_router_link, { to: "/instructor-details" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('menu.profile')), 1)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("ul", _hoisted_48, [
                      _createElementVNode("li", null, [
                        _createVNode(_component_router_link, { to: "/instructor-details" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('menu.profile')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("li", null, [
                        _createVNode(_component_router_link, { to: "/courses-sidebar" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('menu.myCourses')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.logOut && _ctx.logOut(...args)))
                        }, _toDisplayString(_ctx.$t('menu.logOut')), 1)
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("li", null, [
                (!_ctx.isAuthenticated)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: "/login",
                      class: "e-btn"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('actions.startNow')), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          false
            ? (_openBlock(), _createElementBlock("div", _hoisted_49, _cache[27] || (_cache[27] = [
                _createElementVNode("form", { action: "#" }, [
                  _createElementVNode("input", {
                    type: "text",
                    placeholder: "Search..."
                  }),
                  _createElementVNode("button", { type: "submit" }, [
                    _createElementVNode("i", { class: "fad fa-search" })
                  ])
                ], -1)
              ])))
            : _createCommentVNode("", true),
          false
            ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
                _createElementVNode("a", {
                  onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.toggleMiniCart && _ctx.toggleMiniCart(...args)))
                }, [
                  _cache[28] || (_cache[28] = _createStaticVNode("<div class=\"header__cart-icon\"><svg viewBox=\"0 0 24 24\"><circle class=\"st0\" cx=\"9\" cy=\"21\" r=\"1\"></circle><circle class=\"st0\" cx=\"20\" cy=\"21\" r=\"1\"></circle><path class=\"st0\" d=\"M1,1h4l2.7,13.4c0.2,1,1,1.6,2,1.6h9.7c1,0,1.8-0.7,2-1.6L23,6H6\"></path></svg></div>", 1)),
                  (_ctx.totalItems > 0)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_51, _toDisplayString(_ctx.totalItems), 1))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ], 2),
    _createElementVNode("div", {
      onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.handleSidebarClose && _ctx.handleSidebarClose(...args))),
      class: _normalizeClass([_ctx.showSidebar ? 'body-overlay opened' : 'body-overlay'])
    }, null, 2)
  ], 64))
}