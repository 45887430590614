import {HttpBase} from '../../di/httpBase';
import {IInstructorDatasource} from "@/core/domain/datasources/instructor.datasource";
import {InstructorEntity} from "@/core/domain/entities/instructor.entity";

class InstructorDatasource implements IInstructorDatasource {
  private _httpBase: HttpBase;

  constructor() {
    this._httpBase = new HttpBase();
  }

  async getInstructorInfo(userId: string): Promise<InstructorEntity> {
    try {
      const res = (await this._httpBase.get('/user/profile-instructor', {userId}));
      if (res?.data?.success) {
        return res.data.data;
      }
      return {} as InstructorEntity;
    } catch (error) {
      console.error(error)
      throw error;
    }
  }
}

export {
  InstructorDatasource
}