import {Store} from 'vuex';
import {Payment} from "@/core/domain/entities/payment.entity";
import {IPaymentsRepository} from "@/core/domain/repositories/payments.repository";
import {PaymentsRepository} from "@/infrastructure/data/repositories/payments.repository.impl";

export class PaymentsUseCase {
    private _repo: IPaymentsRepository;
    private _store: Store<unknown>;

    constructor(store: Store<unknown>) {
        this._repo = new PaymentsRepository();
        this._store = store;
    }

    async createPayment(payment: Payment): Promise<{
        paymentId: string;
        publicKey: string;
        externalClientSecret: string;
    }> {
        return await this._repo.createPayment(payment);
    }

    async confirmPayment(status: string,
                         externalPaymentId: string,
                         externalClientSecret: string,
                         paymentMethod: string,
                         amountPaid: number): Promise<string> {
        return await this._repo.confirmPayment(status, externalPaymentId, externalClientSecret, paymentMethod, amountPaid);
    }


    async assignCourse(userId: string,
                       courseId: string,
                       totalPaid: number,
                       paymentId: string,
                         ): Promise<boolean> {
        return await this._repo.assignCourse(userId, courseId, totalPaid, paymentId);
    }


}