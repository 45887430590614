import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  class: "tab-pane fade",
  id: "curriculum",
  role: "tabpanel",
  "aria-labelledby": "curriculum-tab"
}
const _hoisted_2 = { class: "course__curriculum" }
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "accordion-item mb-50" }
const _hoisted_5 = ["id"]
const _hoisted_6 = ["data-bs-target", "aria-expanded", "aria-controls"]
const _hoisted_7 = ["id", "aria-labelledby", "data-bs-parent"]
const _hoisted_8 = { class: "accordion-body" }
const _hoisted_9 = { class: "course__curriculum-info" }
const _hoisted_10 = { class: "course__curriculum-meta" }
const _hoisted_11 = { class: "time" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.course.modules, (module, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "accordion",
          key: module._id+index,
          id: index > 0 ? 'course__accordion' : `course__accordion-${index+1}`
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h2", {
              class: "accordion-header",
              id: module._id
            }, [
              _createElementVNode("button", {
                class: "accordion-button",
                type: "button",
                "data-bs-toggle": "collapse",
                "data-bs-target": `#${module._id}-content`,
                "aria-expanded": module.expanded,
                "aria-controls": `${module._id}-content`
              }, _toDisplayString(module.title), 9, _hoisted_6)
            ], 8, _hoisted_5),
            _createElementVNode("div", {
              id: `${module._id}-content`,
              class: "accordion-collapse collapse show",
              "aria-labelledby": module.title,
              "data-bs-parent": index > 0 ? 'course__accordion' : `course__accordion-${index+1}`
            }, [
              _createElementVNode("div", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(module.sections, (lecture) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: lecture.name+index,
                    class: "course__curriculum-content d-sm-flex justify-content-between align-items-center"
                  }, [
                    _createElementVNode("div", _hoisted_9, [
                      _cache[0] || (_cache[0] = _createStaticVNode("<svg class=\"document\" viewBox=\"0 0 24 24\"><path class=\"st0\" d=\"M14,2H6C4.9,2,4,2.9,4,4v16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8L14,2z\"></path><polyline class=\"st0\" points=\"14,2 14,8 20,8 \"></polyline><line class=\"st0\" x1=\"16\" y1=\"13\" x2=\"8\" y2=\"13\"></line><line class=\"st0\" x1=\"16\" y1=\"17\" x2=\"8\" y2=\"17\"></line><polyline class=\"st0\" points=\"10,9 9,9 8,9 \"></polyline></svg>", 1)),
                      _createElementVNode("h3", null, _toDisplayString(lecture.title), 1)
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("span", _hoisted_11, [
                        _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fa fa-clock" }, null, -1)),
                        _createTextVNode(" " + _toDisplayString(lecture.duration) + " min", 1)
                      ])
                    ])
                  ]))
                }), 128))
              ])
            ], 8, _hoisted_7)
          ])
        ], 8, _hoisted_3))
      }), 128))
    ])
  ]))
}