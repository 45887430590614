<template>
  <section class="teacher__area pt-100 pb-110">
    <div class="page__title-shape">
      <img class="page-title-shape-5 d-none d-sm-block" src="@/assets/img/page-title/page-title-shape-1.png" alt="">
      <img class="page-title-shape-6" src="@/assets/img/page-title/page-title-shape-6.png" alt="">
      <img class="page-title-shape-3" src="@/assets/img/page-title/page-title-shape-3.png" alt="">
      <img class="page-title-shape-7" src="@/assets/img/page-title/page-title-shape-4.png" alt="">
    </div>
    <div class="container">
      <div class="row">
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
          <div class="teacher__details-thumb p-relative w-img pr-30">
            <img :src="instructor?.coverLink?.fileLink" alt="">
            <div class="teacher__details-shape">
              <img class="teacher-details-shape-1" src="@/assets/img/teacher/details/shape/shape-1.png" alt="">
              <img class="teacher-details-shape-2" src="@/assets/img/teacher/details/shape/shape-2.png" alt="">
            </div>
          </div>
        </div>
        <div class="col-xxl-8 col-xl-8 col-lg-8">
          <div class="teacher__wrapper">
            <div class="teacher__top d-md-flex align-items-end justify-content-between">
              <div class="teacher__info">
                <h4>{{ instructor.fullName }}</h4>
                <span>{{ instructor.jobTitle }}</span>
              </div>
              <div class="teacher__rating">
                <h5>Review:</h5>
                <div class="teacher__rating-inner d-flex align-items-center">
                  <ul>
                    <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                    <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                    <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                    <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                    <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                  </ul>
                  <p>4.5</p>
                </div>
              </div>
              <div class="teacher__social-2">
                <h4>Follow Me:</h4>
                <ul>
                  <li v-if="instructor.facebook">
                    <a :href="`https://www.facebook.com/${instructor.facebook}`" target="_blank"
                      rel="noopener noreferrer">
                      <i class="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li v-if="instructor.instagram">
                    <a :href="`https://www.instagram.com/${instructor.instagram}`" target="_blank"
                      rel="noopener noreferrer">
                      <i class="fab fa-instagram ms-2"></i>
                    </a>
                  </li>
                </ul>

              </div>
              <div class="teacher__follow mb-5">
              </div>
            </div>
            <div class="teacher__bio">
              <h3>Short Bio</h3>
              <p>{{ instructor.biography }}</p>
            </div>
            <div class="teacher__courses pt-55">
              <div class="section__title-wrapper mb-30">
                <h2 class="section__title">Teacher <span class="yellow-bg yellow-bg-big">Course<img
                      src="@/assets/img/shape/yellow-bg.png" alt=""></span></h2>
              </div>
              <div class="teacher__course-wrapper">
                <div class="row">

                  <div v-for="course in courses" :key="course.courseId" class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                    <div class="course__item white-bg mb-30 fix">
                      <div class="course__thumb w-img p-relative fix">
                        <router-link :to="`/course-details/${course.courseId}`">
                          <img :src="course.coverLinks?.thumbnailLink" alt="">
                        </router-link>
                        <div class="course__tag">
                          <a href="#" class="green">{{ course.category || 'Marketing' }}</a>
                        </div>
                      </div>
                      <div class="course__content">
                        <div class="course__meta d-flex align-items-center justify-content-between">
                          <!--                          <div class="course__lesson">
                                       <span><i class="far fa-book-alt"></i>
                                       {{ course.lesson }} Lesson</span>
                          </div>-->
                          <!--                          <div class="course__rating">
                                       <span><i class="icon_star"></i>
                                       {{ course.priceUSD }} (44)</span>
                          </div>-->
                        </div>
                        <h3 class="course__title">
                          <router-link :to="`/course-details/${course.courseId}`">
                            {{ course?.detail?.name }}
                          </router-link>
                        </h3>
                        <div class="course__teacher d-flex align-items-center">
                          <div class="course__teacher-thumb mr-15">
                            <img :src="instructor?.coverLink?.thumbnailLink" alt="">
                          </div>
                          <h6><a href="#">{{ instructor?.fullName }}</a></h6>
                        </div>
                      </div>
                      <div class="course__more d-flex justify-content-between align-items-center">
                        <div class="course__status d-flex align-items-center">
                          <span :class="green">${{ course.priceUSD }}</span>
                          <!--                          <span class="old-price">${{ course.oldPrice }}</span>-->
                        </div>
                        <div class="course__btn">
                          <router-link :to="`/course-details/${course.courseId}`" class="link-btn">
                            {{ $t('course.watchCourse') }}
                            <i class="far fa-arrow-right"></i>
                            <i class="far fa-arrow-right"></i>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import CourseMixin from '@/mixins/courseItemsMixin';
import { mapGetters, useStore } from "vuex";
import { key } from "@/infrastructure/di/store";
import { defineComponent } from "vue";
import { CoursesUseCase } from "@/core/usecases/courses.usecase";
import { InfoProducerUseCase } from "@/core/usecases/infoProducer.usecase";
import { InstructorEntity } from "@/core/domain/entities/instructor.entity";
import { Course } from "@/core/domain/entities/course.entity";

export default defineComponent({
  name: 'InstructorDetailsArea',
  mixins: [CourseMixin],

  setup() {
    const store = useStore(key);
    return {
      store
    }
  },
  async mounted() {
    if (this.infoProducerId) {
      await this.getInfoProducerInfo();
      const coursesUseCase = new CoursesUseCase(this.store);
      const paginatedCourse = await coursesUseCase.getCoursesByInstructor(this.infoProducerId as string, 1, 30, this.$i18n.locale);
      this.courses = paginatedCourse.data;
    }

  },
  data() {
    return {
      infoProducerId: this.$route.query.uid,
      instructor: {} as InstructorEntity,
      courses: [] as Array<Course>
    }
  },
  methods: {

    async getInfoProducerInfo() {
      const useCase = new InfoProducerUseCase();
      if (this.infoProducerId) {
        const instructor = await useCase.getInstructorInfo(this.infoProducerId as string);
        if (instructor) {
          this.instructor = instructor;
        }
      }
    },
    goToCreate() {
      this.$router.push('/new-course');
    }
  },
  computed: {
    ...mapGetters(['user']),
  },
});
</script>