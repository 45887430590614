<template>
   <div class="col-xxl-4 col-xl-4 col-lg-4">
     <div class="course__sidebar pl-70 p-relative">
       <div class="course__shape">
         <img class="course-dot" src="@/assets/img/course/course-dot.png" alt="">
       </div>
       <div class="course__sidebar-widget-2 white-bg mb-20">
         <div class="course__video">
           <div class="course__video-thumb w-img mb-25">
             <img :src="imageFileLink" alt="">
             <div class="course__video-play">
               <button v-b-modal.modal-center :href="imageFileLink" data-fancybox="" class="play-btn"> <i class="fas fa-play"></i> </button>
            </div>
           </div>
           <div class="course__enroll-btn mb-3">
             <button class="e-btn e-btn-7 w-100" @click="addToCart">{{ $t('cart.buyNow') }}
               <i class="fa-solid fa-basket-shopping"></i>
             </button>
             <div class="mt-3" style="display: flex; justify-content: center;">
               <span style="color: blue; font-weight: bold" class="uppercase">{{ $t('cart.buyNow') }}</span>
             </div>
           </div>
           <div class="course__video-meta mb-25 d-flex align-items-center justify-content-between" v-if="course?.priceUSD > 0">
             <div class="course__video-price">
               <h5>$ {{ course?.priceUSD?.toFixed(2) }}</h5>
               <h5 class="old-price">{{ course?.priceFull?.toFixed(2) }}</h5>
             </div>
             <div class="course__video-discount">
               <span>{{ discountPercentage }}% OFF</span>
             </div>
           </div>
           <div v-else class="mb-25 d-flex align-items-center justify-content-center">
             <h2 class="text-success text-uppercase"> {{ $t('cart.free') }} </h2>
           </div>
           <div class="course__video-content mb-35">
             <ul>
<!--               <li class="d-flex align-items-center">
                 <div class="course__video-icon">
                   <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
                     <circle class="st0" cx="8" cy="8" r="6.7" />
                     <polyline class="st0" points="8,4 8,8 10.7,9.3 " />
                   </svg>
                 </div>
&lt;!&ndash;                 <div class="course__video-info">
                   <h5><span>Difficulty :</span>{{ course.difficulty }}</h5>
                 </div>&ndash;&gt;
               </li>-->
               <li class="d-flex align-items-center">
                 <div class="course__video-icon">
                   <svg>
                     <path class="st0" d="M13.3,14v-1.3c0-1.5-1.2-2.7-2.7-2.7H5.3c-1.5,0-2.7,1.2-2.7,2.7V14" />
                     <circle class="st0" cx="8" cy="4.7" r="2.7" />
                   </svg>
                 </div>
                 <div class="course__video-info">
                   <h5><span>Infoproductor :</span>{{ course.teacherName }}</h5>
                 </div>
               </li>
               <li class="d-flex align-items-center">
                 <div class="course__video-icon">
                   <svg>
                     <circle class="st0" cx="8" cy="8" r="6.7" />
                     <line class="st0" x1="1.3" y1="8" x2="14.7" y2="8" />
                     <path class="st0" d="M8,1.3c1.7,1.8,2.6,4.2,2.7,6.7c-0.1,2.5-1,4.8-2.7,6.7C6.3,12.8,5.4,10.5,5.3,8C5.4,5.5,6.3,3.2,8,1.3z" />
                   </svg>
                 </div>
                 <div class="course__video-info">
                   <h5><span>{{ $t('course.language') }} :</span>{{course?.defaultLanguage ==='es'? 'English': 'Spanish'}}</h5>
                 </div>
               </li>
             </ul>
           </div>
           <div class="course__payment mb-35">
             <h3>Payment:</h3>
             <a @click="addToCart">
               <img src="@/assets/img/course/payment/payment-1.png" alt="">
               <img class="ml-5 w-25" src="@/assets/img/course/payment/stripe.png" alt="">
             </a>
           </div>
         </div>
       </div>
 
       <!-- RelatedCourse start -->
       <!-- <RelatedCourse DetailsPage="course__sidebar-widget-2 white-bg mb-20" /> -->
       <!-- RelatedCourse end -->
     </div>
   </div>
 
   <!-- video modal start-->
   <div>
     <div class="video-modal-wrapper">
       <b-modal id="modal-center" centered>
         <p><iframe :src="coverVideoFileLink" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>
       </b-modal>
     </div>
   </div>
   <!-- video modal end-->
 </template>
 
 <script lang="ts">
 import {defineComponent, PropType} from 'vue';
 // import RelatedCourse from '../common/RelatedCourse.vue';
 import {Course} from '@/core/domain/entities/course.entity';
 import {useStore} from "vuex";
 import {key} from "@/infrastructure/di/store";

 export default defineComponent({
  name: 'courseSidebar',
  props: {
    course: {
      type: Object as PropType<Course>,
      required: true
    },
  },
  // components: {
  //   RelatedCourse,
  // },
  computed: {
    discountPercentage() {
      const priceFull = this.course?.priceFull;
      const priceUSD = this.course?.priceUSD;

      if (priceFull && priceUSD && priceFull > 0) {
        const discount = ((priceFull - priceUSD) / priceFull) * 100;
        return discount.toFixed(2); 
      }

      return "0"; // Return 0% if prices are invalid
    },
    coverVideoFileLink() {
      const videoLink = this.course?.coverLinks?.find(link => link.fileType === 'video');
      return videoLink ? videoLink.fileLink : '';
    },
    imageFileLink() {
      const imageLink = this.course.coverLinks?.find(link => link.fileType === 'image');
      return imageLink ? imageLink.fileLink : '';
    },
  },
  methods: {
    addToCart() {
      console.log('clicked');

      let thumbnail = this.course.coverLinks?.find(link => link.fileType === 'image');
      this.store.dispatch('shoppingCart/openMiniCart');
      this.store.dispatch('shoppingCart/addItem', {
        courseId: this.course.courseId, // ID del ítem
        courseName: this.course.detail?.title, // Nombre del ítem
        price: this.course.priceUSD, // Precio del ítem
        creatorId: this.course.teacherId, // ID del creador
        creatorName: this.course.teacherName, // Nombre del creador
        linkImage: thumbnail ? thumbnail.thumbnailLink : '',
      });
    },
  },
  setup() {
    const store = useStore(key);
    return {
      store
    };
  },
});
</script>