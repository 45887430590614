import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '../../../assets/img/fondo.png'


const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 col-md-6 mt-60" }
const _hoisted_3 = { class: "section__title-wrapper text-center mb-35" }
const _hoisted_4 = { class: "section__title" }
const _hoisted_5 = { href: "#" }
const _hoisted_6 = { class: "sign__wrapper" }
const _hoisted_7 = { class: "sign__form" }
const _hoisted_8 = { class: "sign__input-wrapper mb-25" }
const _hoisted_9 = { class: "sign__input" }
const _hoisted_10 = ["placeholder"]
const _hoisted_11 = {
  key: 0,
  class: "error-message"
}
const _hoisted_12 = { class: "sign__input-wrapper mb-10" }
const _hoisted_13 = { class: "sign__input" }
const _hoisted_14 = ["placeholder"]
const _hoisted_15 = { class: "sign__action d-sm-flex justify-content-between mb-30" }
const _hoisted_16 = { class: "sign__forgot" }
const _hoisted_17 = { href: "#" }
const _hoisted_18 = {
  key: 0,
  class: "error-message"
}
const _hoisted_19 = ["disabled"]
const _hoisted_20 = { class: "sign__new text-center mt-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "col-6 d-none d-md-flex justify-content-end align-items-center" }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "Imagen",
          class: "img-fluid left-login-img"
        })
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h2", _hoisted_4, [
            _createTextVNode(_toDisplayString(_ctx.$t("login.title")), 1),
            _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('login.startToTeach')), 1)
          ]),
          _createElementVNode("p", null, [
            _createTextVNode("¡" + _toDisplayString(_ctx.$t('login.dontHaveAcc')) + " ", 1),
            _createElementVNode("a", _hoisted_5, _toDisplayString(_ctx.$t('actions.registerHere')) + "!", 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("form", null, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("h5", null, _toDisplayString(_ctx.$t('forms.email')), 1),
                _createElementVNode("div", _hoisted_9, [
                  _withDirectives(_createElementVNode("input", {
                    type: "email",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                    placeholder: _ctx.$t('forms.email'),
                    required: "",
                    pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$"
                  }, null, 8, _hoisted_10), [
                    [_vModelText, _ctx.email]
                  ]),
                  _cache[4] || (_cache[4] = _createElementVNode("i", { class: "fal fa-envelope" }, null, -1))
                ]),
                (_ctx.email && !_ctx.validateEmail(_ctx.email))
                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, "Ingrese un correo electrónico válido"))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("h5", null, _toDisplayString(_ctx.$t('forms.password')), 1),
                _createElementVNode("div", _hoisted_13, [
                  _withDirectives(_createElementVNode("input", {
                    type: "password",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
                    placeholder: _ctx.$t('forms.password'),
                    required: ""
                  }, null, 8, _hoisted_14), [
                    [_vModelText, _ctx.password]
                  ]),
                  _cache[5] || (_cache[5] = _createElementVNode("i", { class: "fal fa-lock" }, null, -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("a", _hoisted_17, _toDisplayString(_ctx.$t('login.forgotPassword')), 1)
                ])
              ]),
              (!_ctx.email || !_ctx.password)
                ? (_openBlock(), _createElementBlock("div", _hoisted_18, "Los campos son obligatorios"))
                : _createCommentVNode("", true),
              _createElementVNode("button", {
                type: "button",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args))),
                disabled: !_ctx.email || !_ctx.password,
                class: "e-btn w-100"
              }, _toDisplayString(_ctx.$t('login.title')), 9, _hoisted_19),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("p", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('login.areUNew')) + " ", 1),
                  _createVNode(_component_router_link, { to: "/home" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('actions.register')), 1)
                    ]),
                    _: 1
                  })
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}