import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "course__item white-bg mb-30 fix" }
const _hoisted_2 = { class: "course__thumb w-img p-relative fix max-h" }
const _hoisted_3 = {
  key: 0,
  class: "w-100 min-height-img"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "h-max min-height-img"
}
const _hoisted_6 = { class: "course__tag" }
const _hoisted_7 = { class: "course__content" }
const _hoisted_8 = { class: "course__meta d-flex align-items-center justify-content-between" }
const _hoisted_9 = { class: "course__lesson" }
const _hoisted_10 = { class: "course__rating" }
const _hoisted_11 = { class: "course__title" }
const _hoisted_12 = { class: "course__teacher d-flex align-items-center" }
const _hoisted_13 = { class: "course__teacher-thumb mr-15" }
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "course__more d-flex justify-content-between align-items-center" }
const _hoisted_16 = { class: "course__status d-flex align-items-center" }
const _hoisted_17 = { class: "course__btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courses, (course, index) => {
    return (_openBlock(), _createElementBlock("div", {
      key: course.courseId,
      class: "col-xxl-4 col-xl-4 col-lg-4 col-md-6"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (course.coverLinks)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("img", {
                  src: course.coverLinks.fileLink,
                  alt: "",
                  class: "h-max-img"
                }, null, 8, _hoisted_4)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_5)),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_router_link, {
              to: `/course-details/${course.courseId}`
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(course?.categories && course.categories.length > 0 ? course?.categories[0] : ''), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("span", null, [
                _cache[0] || (_cache[0] = _createElementVNode("i", { class: "far fa-book-alt" }, null, -1)),
                _createTextVNode(_toDisplayString(course.detail?.lessons ?? _ctx.courseItems[index]?.lesson) + " Modulos", 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("span", null, [
                _cache[1] || (_cache[1] = _createElementVNode("i", { class: "icon_star" }, null, -1)),
                _createTextVNode(_toDisplayString(course.rating) + " (" + _toDisplayString(course.totalRates) + ")", 1)
              ])
            ])
          ]),
          _createElementVNode("h3", _hoisted_11, [
            _createVNode(_component_router_link, {
              to: `/course-details/${course.courseId}`
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(course.detail?.title), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("img", {
                src: course.userProfilePicture ?? require('@/assets/img/course/teacher/teacher.jpg'),
                alt: ""
              }, null, 8, _hoisted_14)
            ]),
            _createElementVNode("h6", null, [
              _createVNode(_component_router_link, {
                to: `/instructor-details?uid=${course.teacherId}`
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(course.teacherName), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("span", null, "$" + _toDisplayString(course.isFree ? 'Free' : course.priceUSD), 1)
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createVNode(_component_router_link, {
              to: `/course-details/${course.courseId}`
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('actions.knowMore')) + " ", 1),
                _cache[2] || (_cache[2] = _createElementVNode("i", { class: "far fa-arrow-right" }, null, -1))
              ]),
              _: 2
            }, 1032, ["to"])
          ])
        ])
      ])
    ]))
  }), 128))
}